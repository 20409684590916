import React from "react";
import {Line} from "react-chartjs-2";
import "chartjs-plugin-annotation";
import moment from "moment-timezone";
import isEqual from "lodash.isequal";
import FavoriteButton from "./FavoriteButton";
import axios from "axios";
import {PingButton} from "./PingButton";

const generateData = (event) => {
  const reduceFct = (acc, current) => {
    const currDate = moment(current.date).utc().format();
    if (!acc.includes(currDate)) {
      return [...acc, currDate];
    }
    return acc;
  };

  const allDatesTM = event.ticketmasters.reduce(reduceFct, []);
  const allDatesSG = event.seatgeeks.reduce(reduceFct, allDatesTM);
  const allDatesSH = event.stubhubs.reduce(reduceFct, allDatesSG);
  const allDatesDS = event.ds ? event.ds.reduce(reduceFct, allDatesSH) : allDatesSH;

  const dateLabels = allDatesDS.sort((a, b) => {
    return moment(a).isBefore(moment(b)) ? -1 : 1;
  });

  const seatgeeksData = [];
  const ticketmastersData = [];
  const stubhubsData = [];
  const dsData = [];

  if (event.seatgeeks.length > 0) {
    dateLabels.forEach((date) => {
      const foundData = event.seatgeeks.find(
        (sg) => moment(sg.date).utc().format() === date
      );
      if (foundData) {
        seatgeeksData.push(foundData);
      } else {
        seatgeeksData.push({date, quantity: null});
      }
    });
  }
  if (event.ticketmasters.length > 0) {
    dateLabels.forEach((date) => {
      const findFunc = (evt) => moment(evt.date).utc().format() === date;

      let sosData = event.ticketmasters.find(findFunc);

      if (!sosData) {
        ticketmastersData.push({date, quantity: null});
      } else {
        ticketmastersData.push(sosData);
      }
    });
  }
  if (event.stubhubs.length > 0) {
    dateLabels.forEach((date) => {
      const findFunc = (evt) => moment(evt.date).utc().format() === date;

      let sosData = event.stubhubs.find(findFunc);

      if (!sosData) stubhubsData.push({date, quantity: null})
      else stubhubsData.push(sosData)
    });
  }
  if (event.ds && event.ds.length > 0) {
    dateLabels.forEach((date) => {
      const findFunc = (evt) => moment(evt.date).utc().format() === date;

      let sosData = event.ds.find(findFunc);

      if (!sosData) {
        dsData.push({date, quantity: null});
      } else {
        dsData.push(sosData);
      }
    });
  }

  return {dateLabels, seatgeeksData, ticketmastersData, stubhubsData, dsData};
};

export default class SingleGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetailed: false,
    };
  }

  // async componentDidMount() {
  //   await axios
  //     .get(`/api/bowman/events/${this.props.event.tm_id}/pwd`)
  //     .then((res) => {
  //       this.setState({ password: res.data });
  //     });
  // }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      !isEqual(nextProps, this.props) ||
      nextState.showDetailed !== this.state.showDetailed
    );
  }

  render() {
    const {
      event,
      settings,
      onChangeFavoriteStatus,
      onGraphChange,
      nextId,
      previousId,
      isWatched,
    } = this.props;

    const {dateLabels, seatgeeksData, ticketmastersData, stubhubsData, dsData} =
      generateData(event, this.state.showDetailed);

    const beforeDate = moment(event.sale_start_date).add(30, "seconds");
    const afterDate = moment(event.sale_start_date).add(90, "seconds");

    const firstPresalePoint = ticketmastersData.find(
      (tm) =>
        moment(tm.date).isAfter(beforeDate) &&
        moment(tm.date).isBefore(afterDate)
    );

    const labels = dateLabels.map((date) => {
      return moment.utc(date).local().seconds(0);
    });

    const onPingClick = async (event) => {
      const response = await axios
        .get(
          `/api/shows/ping?tm_id=${event.tm_id}&sg_id=${event.sg_id}&graph_id=${event.id}`
        )
        .catch((err) => {
          console.log(err);
        });

      const tm_data = response.data.tm;
      const sg_data = response.data.sg;

      if (tm_data) event.ticketmasters.push(data);
      if (sg_data) event.seatgeeks.push(data);
    };

    const data = {
      labels: labels,
      datasets: [
        {
          fill: false,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          pointBorderColor: "rgba(75,192,192,1)",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          label: "Ticketmaster",
          data: ticketmastersData.map((tm) => (tm ? tm.quantity : undefined)),
          yAxisID: "y-axis-1",
        },
      ],
    };

    if (seatgeeksData.length > 0) {
      data.datasets.push({
        fill: false,
        label: "Seatgeek",
        spanGaps: true,
        data: seatgeeksData.map((sg) => (sg ? sg.quantity : null)),
        backgroundColor: "#f6a821",
        borderColor: "#f6a821",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "#f6a821",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        hoverBackgroundColor: "#f6a821",
        hoverBorderColor: "#f6a821",
        yAxisID: "y-axis-2",
      });
    }

    if (stubhubsData.length > 0) {
      data.datasets.push({
        fill: false,
        label: "Stubhub",
        spanGaps: true,
        data: stubhubsData.map((sh) => (sh ? sh.quantity : null)),
        backgroundColor: "#ff0000",
        borderColor: "#ff0000",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "#ff0000",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        hoverBackgroundColor: "#ff0000",
        hoverBorderColor: "#ff0000",
        yAxisID: "y-axis-2",
      });
    }

    if (dsData.length > 0) {
      data.datasets.push({
        fill: false,
        label: "DS",
        spanGaps: true,
        data: dsData.map((ds) => (ds ? ds.quantity : null)),
        backgroundColor: "#eeff00",
        borderColor: "#eeff00",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "#eeff00",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        hoverBackgroundColor: "#eeff00",
        hoverBorderColor: "#eeff00",
        yAxisID: "y-axis-2",
      });
    }

    const annotation = {
      annotations: [
        {
          type: "line",

          // optional drawTime to control layering, overrides global drawTime setting
          drawTime: "afterDatasetsDraw",

          // optional annotation ID (must be unique)
          id: "a-line-1",

          // set to 'vertical' to draw a vertical line
          mode: "vertical",

          // ID of the scale to bind onto
          scaleID: "x-axis-1",

          // Data value to draw the line at
          value: firstPresalePoint ? firstPresalePoint.date : null,

          // Optional value at which the line draw should end
          //endValue: 3500,

          // Line color
          borderColor: "red",

          // Line width
          borderWidth: 2,

          // Line dash
          // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
          borderDash: [2, 2],

          // Line Dash Offset
          // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
          borderDashOffset: 5,

          label: {
            // Background color of label, default below
            backgroundColor: "rgba(0,0,0,0.8)",

            // Font family of text, inherits from global
            fontFamily: "sans-serif",

            // Font size of text, inherits from global
            fontSize: 12,

            // Font style of text, default below
            fontStyle: "bold",

            // Font color of text, default below
            fontColor: "#fff",

            // Padding of label to add left/right, default below
            xPadding: 6,

            // Padding of label to add top/bottom, default below
            yPadding: 6,

            // Radius of label rectangle, default below
            cornerRadius: 6,

            // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
            position: "center",

            // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
            // For horizontal lines positioned left or right, negative values move
            // the label toward the edge, and positive values toward the center.
            xAdjust: 0,

            // Adjustment along y-axis (top-bottom) of label relative to above number (can be negative)
            // For vertical lines positioned top or bottom, negative values move
            // the label toward the edge, and positive values toward the center.
            yAdjust: 0,

            // Whether the label is enabled and should be displayed
            enabled: true,

            // Text to display in label - default is null. Provide an array to display values on a new line
            content: "Presale",
          },
        },
      ],
    };

    let options = {
      annotation: this.state.showDetailed ? annotation : null,
      maintainAspectRatio: true,
      spanGaps: true,
      responsive: true,
      elements: {
        line: {
          tension: 0.5, //settings.curve === "no" ? 0 : 0.5,
        },
      },
      title: {
        display: false,
        text: `(${parseInt(event.sale_type) === 0 ? "PRE" : "PUB"}) ${moment
          .tz(event.sale_start_date, "America/Toronto")
          .format("h:mm A")} of ${event.event_name} on ${moment
          .tz(event.event_date, "America/Toronto")
          .format("MMM Do YYYY hh:mm A")}`,
        fontColor: "#ffffff",
        fontSize: settings.graphPerLine === "2" ? 14 : 18,
      },
      tooltips: {
        mode: "index",
        intersect: false,
        position: "average",
      },
      legend: {
        labels: {
          fontColor: "#ffffff",
          fontSize: settings.graphPerLine === "2" ? 10 : 13,
        },
      },
      scales: {
        xAxes: [
          {
            id: "x-axis-1",
            type: "time",
            distribution: "series",
            time: {
              tooltipFormat: "h:mm a",
              minUnit: "minute",
            },
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Time",
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            ticks: {
              beginAtZero: "yes", //settings.beginAtZero === "yes",
            },
            scaleLabel: {
              display: true,
              labelString: "Ticketmaster Quantity",
              fontColor: "#ffffff",
              fontSize: settings.graphPerLine === "2" ? 11 : 14,
            },
          },
        ],
      },
    };

    if (seatgeeksData.length > 0 || dsData.length > 0 || stubhubsData.length > 0) {
      options.scales.yAxes.push({
        type: "linear",
        display: true,
        position: "right",
        id: "y-axis-2",
        ticks: {
          beginAtZero: "yes",
        },
        scaleLabel: {
          display: true,
          labelString: `${seatgeeksData.length > 0 ? "Seatgeek" : ""}
            ${dsData.length > 0 ? "DS" : ""}
            ${stubhubsData.length > 0 ? "Stubhub" : ""} Quantity`,
          fontColor: "#ffffff",
          fontSize: settings.graphPerLine === "2" ? 11 : 14,
        },
        // grid line settings
        gridLines: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      });
    }

    return (
      <div
        className={`col-sm-12 col-md-12 col-lg-${
          settings.graphPerLine === "1" ? "12" : "6"
        }`}
        style={{
          fontSize: settings.graphPerLine === "2" ? 14 : 18,
          color: "#FFFFFF",
          marginTop: 40,
        }}
      >
        <div style={{float: "left", display: "flex", gap: 15}}>
          <FavoriteButton
            isFavorite={event.is_favorite}
            onClick={() => onChangeFavoriteStatus(event)}
            style={{
              cursor: "pointer",
              fontSize: 36,
            }}
          />
          <PingButton
            onClick={() => onPingClick(event)}
            style={{
              marginTop: 5,
              cursor: "pointer",
              fontSize: 25,
              color: "#f6a821",
            }}
          />
          <div
            onClick={() => this.props.onWatchClick(event)}
            style={{
              cursor: "pointer",
            }}
          >
            <img
              src={isWatched ? "/eye-open.png" : "/eye-close.png"}
              alt="watchingOrNot"
              style={{width: 34, height: 34}}
            />
          </div>

          {previousId !== null && (
            <i
              onClick={() => {
                onGraphChange(previousId);
              }}
              className={
                "pe page-header-icon pe-7s-angle-left-circle next-arrow-graph"
              }
            />
          )}
        </div>

        <div style={{float: "right"}}>
          {nextId !== null && (
            <i
              onClick={() => {
                onGraphChange(nextId);
              }}
              className={
                "pe page-header-icon pe-7s-angle-right-circle next-arrow-graph"
              }
            />
          )}
        </div>

        <div style={{textAlign: "center", maxWidth: 500, margin: "0 auto"}}>
          <a rel="noopener noreferrer" target="_blank" href={event.url}>
            {` ${event.event_name} on ${moment
              .tz(event.event_date, "UTC")
              .format("MMM Do YYYY h:mm A")}`}
          </a>
          <br/>
          <span>
            {event.venue_name} - {event.city}, {event.state}
            <strong>
            {event.capacity && event.capacity > 0
              ? ` ( Capacity: ${event.capacity.toLocaleString(
                navigator.language,
                {
                  minimumFractionDigits: 0,
                }
              )} )`
              : " Unknown"}
            </strong>
          </span>
          <br/>
          <strong>
            {parseInt(event.sale_type) === 0 ? "Presale" : "Public sale"}
          </strong>{" "}
          -{" "}
          {moment
            .tz(event.sale_start_date, "America/Toronto")
            .format("ddd MMM Do h:mm A")}{" "}
          {event.sg_id && (
            <span style={{marginLeft: 10}}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={` https://bowman.plessinc.com/seatgeek/${event.sg_id}`}
              >
                <i className="fa fa-link" aria-hidden="true">
                  {" "}
                </i>
                SG
              </a>
            </span>
          )}
        </div>

        <Line data={data} options={options}/>
      </div>
    );
  }
}

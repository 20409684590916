import React from "react";

export default ({ isFavorite, style, onClick }) => {
  return (
    <i
      onClick={onClick}
      style={{ ...style, color: isFavorite ? "#f6a821" : "#ffffff" }}
      className="pe page-header-icon pe-7s-star"
    />
  );
};
import React, { Component } from "react";
import ReactTable from "react-table";

class SalesList extends Component {
  render() {
    let columns = [
      {
        Header: "City",
        accessor: "city",
        filtered: true
      },
      {
        Header: "Spotify",
        accessor: "spotifyListeners",
        Cell: props => (
          <div style={{ textAlign: "center" }}>
            {props.value ? props.value : "-999"}
          </div>
        )
      },
      {
        Header: "Youtube",
        accessor: "youtubeViews",
        Cell: props => (
          <div style={{ textAlign: "center" }}>
            {props.value ? props.value : "-999"}
          </div>
        )
      },
      {
        Header: "Instagram",
        accessor: "instagramFollowers",
        Cell: props => (
          <div style={{ textAlign: "center" }}>
            {props.value ? props.value : "-999"}
          </div>
        )
      }
    ];

    const { data } = this.props;
    console.log("data", data);
    return (
      <ReactTable
        columns={columns}
        data={data || []}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toUpperCase()
            .indexOf(filter.value.toUpperCase()) > -1
        }
      />
    );
  }
}

export default SalesList;

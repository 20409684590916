import React from "react";

const Input = ({
  name,
  label,
  type,
  id,
  placeholder,
  error,
  value,
  options,
  onChange,
  children
}) => {
  let input;

  if (type === "select") {
    input = (
      <select
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        className="form-control"
        id={id}
        placeholder={placeholder}
      >
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    );
  } else {
    input = (
      <input
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        className="form-control"
        id={id}
        placeholder={placeholder}
      />
    );
  }

  return <div>{children({ label, input, error, name })}</div>;
};

export default Input;

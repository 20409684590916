import React from 'react'

const Login = () => {
  const PWD = 'tickets09';

  document.title = "Login";

  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const basicErrorMsg = `Invalid email or password. Please try again.`;

  const handleLogin = () => {
    setIsLoading(true);
    setError("");
    setTimeout(() => {
      if (email && password === PWD) {
        localStorage.setItem('pwd', PWD);
        localStorage.setItem('email', email);
        window.location.href = "/";
      } else {
        setIsLoading(false);
        setError(basicErrorMsg);
        setPassword("");
      }
    }, 1000);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >

        <div
          style={{
            width: '400px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '20px',
            backgroundColor: '#3f3f3f',
            borderRadius: '10px',
          }}
        >
          <h1 className="text-center"
              style={{
                borderBottom: '1px solid orange',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
          >
            SOS Login
          </h1>
          <input type="email" className="form-control" placeholder="Email"
                 value={email}
                 onChange={e => setEmail(e.target.value)}
          />

          <input type="password" className="form-control" placeholder="Password"
                 value={password}
                 onChange={e => setPassword(e.target.value)}
                 onKeyDown={e => e.key === 'Enter' && handleLogin()}
          />
          <button className="btn btn-primary btn-block"
                  onClick={handleLogin}
                  disabled={isLoading || !email || !password}
          >
            {isLoading ? "Loading..." : "Login"}
          </button>

        </div>

        {error && (
          <div className="alert alert-danger" role="alert"
               style={{
                 position: 'absolute',
                 bottom: '20px',
               }}
          >
            {error}
          </div>
        )}

      </div>
    </>
  )
}

export default Login

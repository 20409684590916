import React, { Component } from "react";
import moment from "moment-timezone";
import NumberFormatter from "../../../utils/NumberFormatter";

class Tweet extends Component {
  render() {
    const data = this.props.data;
    const date = moment(data.date)
      .tz("America/Vancouver")
      .format("YYYY-MM-DD HH:mm");
    const retweetCount = NumberFormatter.shorten(data.retweetCount, 1);
    const favoriteCount = NumberFormatter.shorten(data.favoriteCount, 1);
    return (
      <div className={"tweetPuck"}>
        <div>{date}</div>
        <a href={data.link}>
          <div>{data.text}</div>
        </a>
        <div>{retweetCount} Retweet</div>
        <div>{favoriteCount} Favorite</div>
      </div>
    );
  }
}

export default Tweet;

import React, { Component } from "react";
import querystring from "query-string";
import SearchBar from "./artist/SearchBar";
import ArtistContainer from "./artist/ArtistContainer";
import ChartmetricSelector from "./artist/ChartmetricSelector";
import Loading from "../../components/Loading";

class ArtistPage extends Component {
  constructor(props) {
    super(props);
    this.state = { q: "", loading: false };
    this.serverUrl = process.env.REACT_APP_SOME_API_URL;
  }

  componentDidMount() {
    const params = querystring.parse(this.props.location.search);
    this.onSearchChange(params.q);
  }

  handleSelecting = () => {
    this.setState({ artist: null, chartmetricInfos: null, loading: true });
  };

  handleSelected = artistId => {
    this.onSearchChange(artistId);
  };

  unlinked = () => {
    const artist = this.state.artist;
    artist.chartmetricId = null;
    this.getChartmetricList(artist);
  };

  onSearchChange = async artistId => {
    if (!!artistId) {
      this.props.history.push(`/pages/some/artist?q=${artistId}`);
      this.setState({ artist: null, loading: true });
      const response = await fetch(
        `${this.serverUrl}/api/artists?id=${artistId}`
      );
      const artist = await response.json();
      this.getChartmetricInfos(artist);
    }
  };

  getChartmetricInfos = async artist => {
    this.setState({ artist, chartmetricInfos: null, loading: true });
    let url = `${this.serverUrl}/api/chartmetric/byNameAndTwitterAccount?id=${
      artist.id
    }`;
    if (!!artist.chartmetricId) {
      url = `${this.serverUrl}/api/chartmetric?id=${artist.chartmetricId}`;
    }
    const response = await fetch(url);
    const result = await response.json();
    this.setState({ chartmetricInfos: result, loading: false });
  };

  getChartmetricList = async artist => {
    this.setState({ artist, chartmetricInfos: null, loading: true });
    const url = `${this.serverUrl}/api/chartmetric/byName?name=${encodeURI(
      artist.name
    )}`;
    const response = await fetch(url);
    const result = await response.json();
    const chartmetricInfos = { artists: result };
    this.setState({ chartmetricInfos, loading: false });
  };

  render() {
    const { q, artist, chartmetricInfos, loading } = this.state;
    return (
      <div>
        <div
          className={`searchBarContainer ${!!artist ? "absolute" : "relative"}`}
        >
          <SearchBar
            value={q}
            onSelectionChange={this.onSearchChange.bind(this)}
          />
        </div>
        {!!artist && !!chartmetricInfos && (
          <div>
            {!!chartmetricInfos.artist && (
              <ArtistContainer
                artist={artist}
                cmArtist={chartmetricInfos.artist}
                cmUrls={chartmetricInfos.urls}
                unlinked={this.unlinked.bind(this)}
              />
            )}
            {!!chartmetricInfos.artists && (
              <ChartmetricSelector
                artists={chartmetricInfos.artists}
                artist={artist}
                onSelecting={this.handleSelecting.bind(this)}
                onSelected={this.handleSelected.bind(this)}
              />
            )}
          </div>
        )}
        {!!loading && <Loading />}
      </div>
    );
  }
}

export default ArtistPage;

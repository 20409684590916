import React, {Component} from "react";

// Component to display the inputs for sending notifications
class SendNotifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            event: props.event,
            graph: props.graph,
            fetchEvents: props.fetchEvents,
            qty: '',
            buyers: '',
            error: false,
        };
    }

    // Update state when new props are received
    componentWillReceiveProps(nextProps) {
        this.setState({
            event: nextProps.event,
            notify: nextProps.notify,
        });
    }

    // Call the API to notify Slack about the event
    async notify(event, qty, buyers) {
        await fetch(`https://concertweek.plessinc.com/api/slack`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                event: {
                    id: event.id,
                    name: event.name,
                    venue: event.venue,
                },
                quantity: parseInt(qty),
                n_buyers: parseInt(buyers)
            })
        });

        await this.state.fetchEvents();
    }

    /**
     * Send notification to Slack
     * @param event<id, name, venue> - event to notify about
     * @param qty<Number> - quantity of tickets
     * @param buyers<Number> - number of buyers
     */
    sendNotification = async (event, qty, buyers) => {
        // If qty or buyers is empty, show error
        if (qty === '' || buyers === '') {
            this.setState({error: true});
            return;
        }
        this.setState({error: false});
        this.notify(
            event,
            qty,
            buyers,
        ).then(() => {
            this.setState({qty: '', buyers: ''});
        })
    }

    /**
     * Hide the event from the list
     * @param event<id, name, venue> - event to hide
     */
    hideEvent = async (event) => {
        await fetch(`https://concertweek.plessinc.com/api/event/hide/${event.id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
        });

        await this.state.fetchEvents();
    }


    // Render the component
    render() {
        const {event, graph, qty, buyers} = this.state;

        // Return the component
        return (
            <div style={{
                margin: graph ? '15px' : '2px 0 0 2px',
                display: 'flex', justifyContent: 'center'
            }}>
                <div
                    onClick={() => {
                        this.sendNotification(event, qty, buyers).then();
                    }}
                    style={{
                        cursor: 'pointer', fontSize: '1.2em',
                        color: '#f6a821',
                    }}
                >
                    {event.notification_sent ? <i className="fa fa-bell"/> :
                        <i className="fa fa-bell-o"/>}

                </div>

                <input
                    type="number" min="0" max="100" step="1" className={'form-control'}
                    placeholder={'Qty'}
                    style={{
                        height: '22px', margin: "0 10px",
                        border: this.state.error && qty === '' && '1px solid red',
                        width: '100px'
                    }}
                    value={qty}
                    onChange={(e) => this.setState({qty: e.target.value})}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter')
                            this.sendNotification(event, qty, buyers).then();
                    }}
                />

                <input
                    type="number" min="0" max="100" step="1" className={'form-control'}
                    placeholder={'Buyers'}
                    style={{
                        height: '22px',
                        border: this.state.error && buyers === '' && '1px solid red',
                        width: '100px'
                    }}
                    value={buyers}
                    onChange={(e) => this.setState({buyers: e.target.value})}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter')
                            this.sendNotification(event, qty, buyers).then();
                    }}
                />

                <div
                    onClick={() => {
                        if (!event.hidden)
                            this.hideEvent(event).then();
                    }}
                    style={{
                        cursor: 'pointer', fontSize: '1.2em',
                        color: event.hidden ? 'gray' : '#f6a821',
                        marginLeft: '10px'
                    }}
                >
                    {event.hidden ? <i className="fa fa-eye-slash"/> :
                        <i className="fa fa-eye"/>}
                </div>

            </div>
        )
    }
}

export default SendNotifications;




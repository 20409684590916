import React, {Component} from "react";
import {stringify} from "query-string";
import GraphList from "./Components/GraphList";
import Filters from "./Components/Filters";
import filterInputs from "./Filters/EventFilter";
import graphFilterInputs from "./Filters/GraphFilter";
import Title from "../../components/Title";
import moment from "moment-timezone";

const getInitialState = (id) => {
  return {
    loading: true,
    pageCount: 0,
    events: [],
    watchEvents: [],
    filterInputs: filterInputs(id),
    graphFilterInputs: graphFilterInputs(id),
    currentPage: 0,
    filters: {
      graphPerLine: localStorage.getItem(`${id}-graphPerLine`) || "2",
      curve: localStorage.getItem(`${id}-curve`) || "yes",
      event_name: localStorage.getItem(`${id}-event_name`) || "",
      sale_start_date: localStorage.getItem(`${id}-sale_start_date`) || "all",
      sale_type: localStorage.getItem(`${id}-sale_type`) || "all",
      beginAtZero: localStorage.getItem(`${id}-beginAtZero`) || "yes",
      graphPerPage: localStorage.getItem(`${id}-graphPerPage`) || 30,
      sortBy: localStorage.getItem(`${id}-sortBy`) || "event_name",
      presaleDays: localStorage.getItem(`${id}-presaleDays` || 7),
    },
  };
};

class ActiveSales extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState(this.props.id);
  }

  onChangedFilters = async (filters) => {
    this.setTimer();
    return new Promise((resolve, reject) => {
      for (const key in filters) {
        localStorage.setItem(`${this.props.id}-${key}`, filters[key]);
      }

      this.setState(
        {
          currentPage: 0,
          loading: true,
          filters: {
            ...this.state.filters,
            ...filters,
          },
        },
        async () => {
          await this.fetchGraphData();
          resolve();
        }
      );
    });
  };

  onChangeFavoriteStatus = async ({is_favorite, id}) => {
    const mapFunction = (currentEvent) => {
      if (currentEvent.id === id) {
        return {
          ...currentEvent,
          is_favorite: !is_favorite,
        };
      }
      return currentEvent;
    };

    this.setState({
      events: this.state.events.map(mapFunction),
    });

    const endPoint = is_favorite ? "remove" : "add";
    await fetch(`/api/favorite/${endPoint}/${id}`, {method: "PUT"});
  };

  async fetchApi(url) {
    const response = await fetch(url, {
      method: "GET",
    });
    return response.json();
  }

  async fetchActiveWatchEvents() {
    const response = await fetch(`/api/dropcheck/checked/all`, {method: "GET"});
    const events = await response.json();
    this.setState({watchEvents: events.events});
  }

  async fetchGraphData(showLoading = true) {
    this.setState({loading: showLoading});

    const query = {
      onlyFavorite: this.props.onlyFavorite ? 1 : 0,
      offset:
        parseInt(this.state.currentPage) *
        parseInt(this.state.filters.graphPerPage),
      limit: parseInt(this.state.filters.graphPerPage),
      filters: JSON.stringify(this.state.filters),
      sort: this.state.filters.sortBy,
      presaleDays: this.state.filters.presaleDays,
      yesterday: this.props.yesterday || false,
    };

    let response = await this.fetchApi(`/api/shows/active?${stringify(query)}`);

    if (response.events.length <= 0) {
      const storageSaleDate = localStorage.getItem(
        `${this.props.id}-sale_date`
      );
      if (storageSaleDate && storageSaleDate !== "all") {
        localStorage.setItem(`${this.props.id}-sale_date`, "all");
      }

      const filters = {...this.state.filters};
      filters.sale_date = "all";
      query.filters = JSON.stringify(filters);
      response = await this.fetchApi(`/api/shows/active?${stringify(query)}`);
    }

    let {events, numberOfRows, allSaleDates} = response;

    events = events.map(event => {
      if (!event.event_date || !moment(event.event_date).isValid()) {
        console.error('Invalid date for event:', event.tm_id);
        return event;
      }

      event.event_date = moment.tz(event.event_date, event.timezone).format('YYYY-MM-DD HH:mm:ss');
      return event;
    });

    console.log(allSaleDates)

    allSaleDates.unshift({
      label: "All",
      value: "all",
    });

    this.setState({
      pageCount: Math.ceil(
        parseInt(numberOfRows) / parseInt(this.state.filters.graphPerPage)
      ),
      loading: false,
      events,
      filterInputs: this.state.filterInputs.map((filter) => {
        if (filter.id === "sale_start_date") {
          return {
            ...filter,
            options: allSaleDates,
          };
        }
        return filter;
      }),
    });
  }

  setTimer() {
    clearInterval(this.timer);
    clearInterval(this.timerActiveWatch);
    this.timer = null;
    this.timerActiveWatch = null;
    this.timer = setInterval(() => this.fetchGraphData(false), 30000);
    //this.timerActiveWatch = setInterval(() => this.fetchActiveWatchEvents(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
    clearInterval(this.timerActiveWatch);
    this.timerActiveWatch = null;
  }

  async componentDidMount() {
    await this.fetchGraphData();
    await this.fetchActiveWatchEvents();
    this.setTimer();
  }

  handlePageClick = ({selected}) => {
    this.setState(
      {
        currentPage: selected,
      },
      () => {
        this.fetchGraphData();
      }
    );
  };

  render() {
    return (
      <div className={"with-padding"}>
        <Title title={"Graphs"} icon={"graph3"}>
          <small>
            {this.props.yesterday && "Yesterday's sales"}
            {this.props.onlyFavorite && "Active sales"}
            {!this.props.yesterday &&
              !this.props.onlyFavorite &&
              "Active sales"}
          </small>
        </Title>

        <div className="row">
          <Filters
            title={"Filters"}
            inputs={this.state.filterInputs}
            onChangedFilters={this.onChangedFilters}
          />
          <Filters
            title={"Graph settings"}
            inputs={this.state.graphFilterInputs}
            onChangedFilters={this.onChangedFilters}
          />
        </div>

        <GraphList
          onChangeFavoriteStatus={this.onChangeFavoriteStatus}
          onPageChange={this.handlePageClick}
          perPage={this.state.filters.graphPerPage}
          currentPage={this.state.currentPage}
          pageCount={this.state.pageCount}
          loading={this.state.loading}
          events={this.state.events}
          watchEvents={this.state.watchEvents}
          onSaveWatchEvent={this.fetchActiveWatchEvents.bind(this)}
          graphSettings={{
            ...this.state.filters,
          }}
        />
      </div>
    );
  }
}

export default ActiveSales;

export default prefix => [
    {
        label: "Event Name",
        name: "event_name",
        type: "text",
        id: "event_name",
        placeholder: "",
        error: null,
        defaultValue: localStorage.getItem(`${prefix}-event_name`) || ""
    },
    {
        label: "Venue Name",
        name: "venue",
        type: "text",
        id: "venue",
        placeholder: "",
        error: null,
        defaultValue: localStorage.getItem(`${prefix}-venue`) || ""
    },
    {
        label: "SG Tickets Sold",
        name: "tickets_sold_sg",
        type: "text",
        id: "tickets_sold_sg",
        placeHolder: "",
        defaultValue: localStorage.getItem(`${prefix}-tickets_sold_sg`) || "",
        error: null
    },
    {
        label: "SG Listings Sold",
        name: "listings_sold_sg",
        type: "text",
        id: "listings_sold_sg",
        placeHolder: "",
        defaultValue: localStorage.getItem(`${prefix}-listings_sold_sg`) || "",
        error: null
    },
    {
        label: "TM MIR",
        name: "tm_mir",
        type: "text",
        id: "tm_mir",
        placeHolder: "",
        defaultValue: localStorage.getItem(`${prefix}-tm_mir`) || "",
        error: null
    },
    {
        label: "Concert Week QTY",
        name: "latest_cw_count",
        type: "text",
        id: "latest_cw_count",
        placeHolder: "",
        defaultValue: localStorage.getItem(`${prefix}-latest_cw_count`) || "",
        error: null
    },
    {
        label: "Decrease (15min)",
        name: "decrease_15",
        type: "text",
        id: "decrease_15",
        placeHolder: "",
        defaultValue: localStorage.getItem(`${prefix}-decrease_15`) || "",
        error: null
    },
    {
        label: "Decrease (Total)",
        name: "decrease",
        type: "text",
        id: "decrease",
        placeHolder: "",
        defaultValue: localStorage.getItem(`${prefix}-decrease_total`) || "",
        error: null
    },
    {
        label: "Notified in Slack",
        name: "notification_sent",
        type: "select",
        id: "notification_sent",
        placeholder: "",
        error: null,
        defaultValue: localStorage.getItem(`${prefix}-notification_sent`) || "0",
        options: [
            {
                value: "0",
                label: "No"
            },
            {
                value: "1",
                label: "Yes"
            },
        ]
    },
    {
        label: "SG Get In",
        name: "sg_getin",
        type: "text",
        id: "number",
        placeHolder: "",
        error: null,
        defaultValue: localStorage.getItem(`${prefix}-sg_getin`) || "",
    },
    {
        label: "SG Listings",
        name: "sg_listings",
        type: "number",
        id: "sg_listings",
        placeHolder: "",
        error: null,
        defaultValue: localStorage.getItem(`${prefix}-sg_listings`) || "",
    },
    {
        label: "Show Hidden",
        name: "hidden",
        type: "select",
        id: "hidden",
        placeholder: "",
        error: null,
        defaultValue: localStorage.getItem(`${prefix}-hidden`) || "0",
        options: [
            {
                value: "0",
                label: "No"
            },
            {
                value: "1",
                label: "Yes"
            },
        ]
    },

    {
        label: "After Date",
        name: "after_date",
        type: "date",
        id: "after_date",
        placeHolder: "",
        error: null,
        defaultValue: localStorage.getItem(`${prefix}-after_date`) || "",
    },
    {
        name: "hr"
    },
    [
        {
            label: "Sort by",
            name: "sortBy",
            type: "select",
            id: "sortBy",
            placeholder: "",
            error: null,
            defaultValue: "delta",
            options: [
                {
                    value: "tickets_sold_sg",
                    label: "Seatgeek Tickets Sold"
                },
                {
                    value: "listings_sold_sg",
                    label: "Seatgeek Listings Sold"
                },
                {
                    value: "tm_mir",
                    label: "TM MIR"
                },
                {
                    value: "latest_cw_count",
                    label: "Concert Week QTY"
                },
                {
                    value: "decrease_15",
                    label: "Decrease (15min)"
                },
                {
                    value: "decrease",
                    label: "Decrease (Total)"
                },
                {
                    value: "sg_getin",
                    label: "SG Get In"
                },
                {
                    value: "sg_listings",
                    label: "SG Listings"
                },
                {
                    value: "after_date",
                    label: "After Date"
                }
            ]
        },
    ]
];
  
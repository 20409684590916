import React, { Component } from "react";
import CppTableContainer from "./cppTable/CppTableContainer";

class CppTablePage extends Component {
  render() {
    return <CppTableContainer />;
  }
}

export default CppTablePage;

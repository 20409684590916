import React, {Component} from "react";
import Title from "../../components/Title";
import SalesList from "./Components/SalesList";
import moment from "moment-timezone";

class ActiveSales extends Component {
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            loading: true
        };
    }

    async componentDidMount() {
        const response = await fetch(`/api/shows/list`, {method: "GET"});
        let {events} = await response.json();

        events = events.map(event => {
            if (!event.event_date || !moment(event.event_date).isValid()) {
                console.error('Invalid date for event:', event.tm_id);
                return event;
            }

            event.event_date = moment.tz(event.event_date, event.timezone).format('YYYY-MM-DD HH:mm:ss');
            return event;
        });

        this.setState({
            events,
            loading: false
        });
    }

    render() {
        return (
            <div className={"with-padding"}>
                <Title title={"Shows on Sale"} icon={"menu"}>
                    <small>Today</small>
                </Title>

                <SalesList
                    id={`active`}
                    loading={this.state.loading}
                    events={this.state.events}
                />
            </div>
        );
    }
}

export default ActiveSales;

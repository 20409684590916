import React, {Component} from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import Loading from "../../../components/Loading";
import moment from "moment/moment";

class EventsList extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            filtered: [],
            events: props.events,
            selectedDate: "",
            sorted: []
        };
    }

    onFilterChange = filtered => {
        localStorage.setItem(`${this.props.id}-filters`, JSON.stringify(filtered));

        this.setState({filtered});
    };

    onSortChange = sorted => {
        this.setState({sorted});
    };

    componentDidMount() {
        const savedFilters = localStorage.getItem(`${this.props.id}-filters`);

        this.setState({
            filtered: savedFilters ? JSON.parse(savedFilters) : [],
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            events: nextProps.events,
            selectedDate: '',
        });
    }

    render() {
        let columns = [
            {
                Header: " ",
                accessor: "sos_url",
                filterable: false,
                Cell: props => (
                    <>
                        {props.original.sos_url && (
                            <a href={props.original.sos_url} target="_blank">
                                <i className="fa fa-link" aria-hidden="true"></i>
                            </a>
                        )}
                    </>
                ),
                width: 20,
            },
            {
                Header: "Event",
                accessor: "event",
                filterable: true,
                filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                Cell: props => (
                    <>
                        {props.original.event_url ? (
                            <a href={props.original.event_url} target="_blank">{props.value}</a>
                        ) : (
                            <div>{props.value}</div>
                        )}
                    </>
                ),
            },
            {
                Header: "Ticket Price",
                accessor: "ticket_price",
                filterable: true,
                filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            },
            {
                Header: "Seller",
                accessor: "seller",
                filterable: true,
                filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            },
            {
                Header: "Password",
                accessor: "pwd",
                filterable: true,
                filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                show: this.props.type === "presale",
            },
            {
                Header: "Venue",
                accessor: "venue",
                filterable: true,
                filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            },
            {
                Header: "City",
                accessor: "city",
                filterable: true,
                filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            },
            {
                Header: "Date",
                accessor: "date",
                filterable: true,
                filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            },
            {
                Header: "PreSale Date",
                accessor: "presale_date",
                filterable: true,
                filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                show: this.props.type === "presale",
            },
            {
                Header: "OnSale Date",
                accessor: "onsale_date",
                filterable: true,
                filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            },
        ];

        const {events, loading, hideButtons, group_by} = this.props;

        const buttons = [];

        if (!hideButtons) {
            const format = group_by === "days" ? "YYYY-MM-DD" : "YYYY-MM-DD HH";

            const groupedEvents = events.reduce((r, a) => {
                const key = this.props.type === "presale" ? moment(a.presale_date).format(format) : moment(a.onsale_date).format(format);
                r[key] = [...r[key] || [], a];
                return r;
            }, {});

            buttons.push(<button key={"all"}
                                 className={`btn ${this.state.selectedDate === '' ? 'btn-primary' : 'btn-default'}`}
                                 onClick={() => this.setState({events, selectedDate: "",})}
                                 style={{marginRight: 10, marginBottom: 10}}>All</button>);
            for (let key in groupedEvents) {
                buttons.push(
                    <button
                        key={key}
                        onClick={() => this.setState({events: groupedEvents[key], selectedDate: key})}
                        className={`btn ${this.state.selectedDate === key ? 'btn-primary' : 'btn-default'}`}
                        style={{marginRight: 10, marginBottom: 10}}
                    >
                        {group_by === "days" ? moment(key).format("MM/DD/YYYY") : moment(key).format("HH:mm")}
                    </button>
                );
            }
        }

        return (
            <div className="row">
                <div style={{marginLeft: 10}}>
                    {buttons}
                </div>
                <div className="col-md-12">
                    <div className="panel panel-filled">
                        <div className="panel-body" style={{display: "block"}}>
                            {!loading && (
                                <ReactTable
                                    onFilteredChange={this.onFilterChange}
                                    onSortedChange={this.onSortChange}
                                    filtered={this.state.filtered}
                                    defaultSorted={this.state.sorted}
                                    defaultSortDesc
                                    columns={columns}
                                    defaultPageSize={this.state.events.length}
                                    showPagination={false}
                                    data={this.state.events}
                                />
                            )}
                            {loading && <Loading/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EventsList;

import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";

const customStyles = {
  content: {
    top: "15%",
    left: "25%",
    right: "25%",
    bottom: "15%",
    color: "#949ba2",
    background: "#2f323b",
    overflow: "unset",
  },
};

const styleSectionTypes = {
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "baseline",
};
const styleCheckbox = { marginRight: "3px" };
const styleLabel = { margin: "0px 3px" };
const styleHR = { marginTop: "2px", marginBottom: "2px" };

function Modal(props) {
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [customMessage, setCustomMessage] = useState("");
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (!!props.eventId) {
      fetchSectionsAndTypes();
    }
  }, [props.eventId]);

  useEffect(() => {
    setCustomMessage(props.watchingCustomMessage || "");
  }, [props.watchingCustomMessage]);

  async function fetchSectionsAndTypes() {
    setLoading(true);
    await Promise.all([fetchTypes(), fetchSections()]);
    setLoading(false);
  }

  const onApplyChanges = async () => {
    setSending(true);
    const body = {
      country: "NA",
      eventId: props.eventId,
      sections: sections.filter((section) => section.isChecked),
      types: types.filter((type) => type.isChecked),
      customMessage: customMessage,
    };
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    await fetch(`${process.env.REACT_APP_COUNTER_API_URL}/dropchecker`, config);
    setSending(false);
    props.onSaveWatch();
    closeModal();
  };

  const removeFromDropchecker = async () => {
    setSending(true);
    const config = {
      method: "DELETE",
    };

    await fetch(
      `${process.env.REACT_APP_COUNTER_API_URL}/dropchecker/${props.eventId}`,
      config
    );
    setSending(false);
    props.onSaveWatch();
    closeModal();
  };

  const fetchTypes = async () => {
    if (!props.eventId) {
      return;
    }
    let typesMapped = [];
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_COUNTER_API_URL}/event/sections/${props.eventId}/NA`
      );
      const rawTypes = await response.json();
      typesMapped = rawTypes.map((type) => {
        return {
          ...type,
          isChecked: props.watchingTypes.includes(type.id),
        };
      });
      setTypes(typesMapped);
      setLoading(false);
    } catch (error) {
      console.error("error", error);
      setTypes(typesMapped);
      setLoading(false);
    }
  };

  const fetchSections = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_COUNTER_API_URL}/event/map/${props.eventId}/NA`
      );

      const rawSections = await response.json();
      const sectionsMapped = rawSections.map((section) => {
        return {
          ...section,
          isChecked: props.watchingSections.includes(section.id),
        };
      });

      setLoading(false);
      setSections(sectionsMapped);
    } catch (error) {
      console.error("error", error);
      setLoading(false);
      setSections([]);
    }
  };

  function isButtonRemoveVisible() {
    return props.watchingTypes.length > 0 || props.watchingSections.length > 0
      ? "block"
      : "none";
  }

  function isMissingValuesWarningVisible() {
    return types.length === 0 && sections.length === 0 ? "block" : "none";
  }

  function onChangeTypes(evt) {
    const newTypes = types.map((type) => {
      if (type.id !== evt.target.name) {
        return type;
      }
      return {
        ...type,
        isChecked: !type.isChecked,
      };
    });

    setTypes(newTypes);
  }

  function onChangeSection(evt) {
    const newSection = sections.map((section) => {
      if (section.id !== evt.target.name) {
        return section;
      }
      return {
        ...section,
        isChecked: !section.isChecked,
      };
    });

    setSections(newSection);
  }

  function closeModal() {
    props.closeModal();
  }

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={() => closeModal()}
      style={customStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={true}
    >
      <h2>Add To dropchecker</h2>
      <div style={{ paddingBottom: "80px", height: "100%" }}>
        {!loading && (
          <div style={{ overflow: "auto", height: "100%" }}>
            <div>
              <hr style={styleHR} />
              <strong>Ticket types</strong>
              <div style={styleSectionTypes}>
                {types.length === 0 && <span>No ticket types available</span>}

                {types.map((type) => {
                  return (
                    <div key={type.id}>
                      <label htmlFor={type.id} style={styleLabel}>
                        <input
                          type="checkbox"
                          name={type.id}
                          id={type.id}
                          key={type.id}
                          checked={type.isChecked}
                          value={type.id}
                          onChange={onChangeTypes}
                          style={styleCheckbox}
                        />
                        {type.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <hr style={styleHR} />
              <strong>Sections</strong>
              <div style={styleSectionTypes}>
                {sections.length === 0 && <span>No sections available</span>}

                {sections.map((section) => {
                  return (
                    <div key={section.id}>
                      <label htmlFor={section.id} style={styleLabel}>
                        <input
                          type="checkbox"
                          name={section.id}
                          id={section.id}
                          key={section.id}
                          checked={section.isChecked}
                          value={section.id}
                          onChange={onChangeSection}
                          style={styleCheckbox}
                        />
                        {section.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>

            <div htmlFor="customMessage">
              <strong>Custom message</strong>
            </div>
            <textarea
              value={customMessage}
              onChange={(e) => setCustomMessage(e.target.value)}
              name="customMessage"
              id="customMessage"
              style={{ width: "100%", color: "#000000", marginBottom: "-6px" }}
            />
            <h2
              style={{
                textAlign: "center",
                display: isMissingValuesWarningVisible(),
              }}
            >
              <a
                href="https://dashboard.plessinc.com/pages/venues"
                target="_blank"
              >
                Add the venue
              </a>{" "}
              ({props.venue}) then add it to the dropchecker :
            </h2>
          </div>
        )}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "20px",
          display: isButtonRemoveVisible(),
        }}
      >
        <button
          style={{ marginLeft: 0, marginRight: 0 }}
          disabled={loading || sending}
          className="btn btn-w-md btn-accent"
          onClick={removeFromDropchecker}
        >
          Remove (Unwatch)
        </button>
      </div>
      <div style={{ position: "absolute", bottom: "10px", right: "20px" }}>
        <button
          style={{ marginLeft: 0, marginRight: 0 }}
          disabled={loading || sending}
          className="btn btn-w-md btn-accent"
          onClick={onApplyChanges}
        >
          Submit
        </button>
      </div>
      {loading && <p>Loading...</p>}
    </ReactModal>
  );
}
export default Modal;

import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import Loading from "../../../components/Loading";
import GraphContainer from "./GraphContainer";
import WatchModal from "./WatchModal";

const baseModal = {
  isOpen: false,
  eventId: "",
  venueName: "",
  watching: { sections: [], types: [], customMessage: "" },
};
class GraphList extends Component {
  constructor(props) {
    super(props);
    // this.showModal.bind(this);
    this.state = {
      modal: baseModal,
    };
  }

  showModal(event, isWatching) {
    console.log(`event`, event);
    let watchingObject = !!isWatching
      ? {
          sections: isWatching.sectionsWatchedId,
          types: isWatching.typesWatchedId,
          customMessage: isWatching.customMessage,
        }
      : { sections: [], types: [], customMessage: "" };
    this.setState({
      modal: {
        isOpen: true,
        eventId: event.event_id,
        venueName: event.venue_name,
        watching: watchingObject,
      },
    });
  }

  closeModal() {
    this.setState({
      modal: baseModal,
    });
  }

  render() {
    const {
      events,
      loading,
      graphSettings,
      pageCount,
      onChangeFavoriteStatus,
      watchEvents,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    const graphs = [];

    for (let i = 1; i <= events.length; i += 2) {
      const isFirstWatched = watchEvents.find(
        (e) => e.eventId === events[i - 1].event_id
      );
      const graph1 = (
        <GraphContainer
          onChangeFavoriteStatus={onChangeFavoriteStatus}
          settings={graphSettings}
          key={events[i - 1].id}
          event={events[i - 1]}
          onWatchClick={(event) => this.showModal(event, isFirstWatched)}
          isWatched={isFirstWatched}
        />
      );

      let graph2 = null;
      if (i < events.length) {
        const isSecondWatched = watchEvents.find(
          (e) => e.eventId === events[i].event_id
        );
        graph2 = (
          <GraphContainer
            onChangeFavoriteStatus={onChangeFavoriteStatus}
            settings={graphSettings}
            key={events[i].id}
            event={events[i]}
            onWatchClick={(event) => this.showModal(event, isSecondWatched)}
            isWatched={isSecondWatched}
          />
        );
      }

      graphs.push(
        <div key={`div-${graph1.key}`} className="row">
          {graph1}
          {graph2}
        </div>
      );
    }
    return (
      <div className="row">
        {graphs.map((event) => {
          return event;
        })}

        <div
          style={{ marginTop: 40, textAlign: "center" }}
          className="col-lg-12"
        >
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={this.props.currentPage}
            onPageChange={this.props.onPageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>

        <WatchModal
          eventId={this.state.modal.eventId}
          venue={this.state.modal.venueName}
          isOpen={this.state.modal.isOpen}
          watchingSections={this.state.modal.watching.sections}
          watchingTypes={this.state.modal.watching.types}
          watchingCustomMessage={this.state.modal.watching.customMessage}
          closeModal={this.closeModal.bind(this)}
          onSaveWatch={this.props.onSaveWatchEvent}
        />
      </div>
    );
  }
}

export default GraphList;

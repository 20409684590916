module.exports = {
  shorten(number, decimal = 1) {
    if (!number) {
      return number;
    }
    const k = Math.pow(10, 3);
    const m = Math.pow(10, 6);
    const g = Math.pow(10, 9);
    if (number >= g) {
      return `${(number / g).toFixed(decimal)}G`;
    } else if (number >= m) {
      return `${(number / m).toFixed(decimal)}M`;
    } else if (number >= k) {
      return `${(number / k).toFixed(decimal)}K`;
    } else if (number <= -g){
      return `${(number / g).toFixed(decimal)}G`;
    } else if (number <= -m) {
      return `${(number / m).toFixed(decimal)}M`;
    } else if (number <= -k) {
      return `${(number / k).toFixed(decimal)}K`;
    }
    return number;
  },
  numberWithSpaces(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
};

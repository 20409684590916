import React, { Component } from "react";
import Title from "../../components/Title";
import EventsList from "./Components/EventsList";

class CWList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      loading: true
    };
  }

  async componentDidMount() {
    const response = await fetch(`/api/concertweek/list`, { method: "GET" });
    const events = await response.json();

    this.setState({
      events,
      loading: false
    });
  }

  render() {
    return (
      <div className={"with-padding"}>
        <Title title={"Concert Week Events"} icon={"menu"}>
          <small>Today</small>
        </Title>

        <EventsList
          id={`active`}
          loading={this.state.loading}
          events={this.state.events}
        />
      </div>
    );
  }
}

export default CWList;

import React from "react";
import TopCities from "./TopCities";
import Loading from "../../../components/Loading";

class TopCitiesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cities: [], loading: false };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });
    const response = await fetch(
      `${process.env.REACT_APP_SOME_API_URL}/api/chartmetric/topcities?id=${this.props.cmArtist.id}`
    );
    try {
      const data = await response.json();
      console.log("cities", data);
      this.setState({ data, loading: false });
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { data, loading } = this.state;
    return (
      <div>
        <h4>Top Cities :</h4>
        {!!loading ? (
          <Loading />
        ) : (
          !!data && (
            <div>
              <span>
                <b>Engagment Rate: </b>
                {(data.instagram.engagementRate * 100).toFixed(2)}{" "}
              </span>
              <span>
                <b> Notable Followers Ratio: </b>
                {(data.instagram.followersRatio * 100).toFixed(2)}{" "}
              </span>
              <span>
                <b> Spotify Popularity: </b>
                {data.spotify.spotifyPopularity}
              </span>
              <TopCities data={data.topCities} />
            </div>
          )
        )}
      </div>
    );
  }
}

export default TopCitiesContainer;

import React from "react";

export const PingButton = ({style, onClick}) => {
  return (
    <i
      onClick={onClick}
      style={{...style}}
      className="fa fa-refresh"
    />
  );
};

import React, { Component } from "react";
import Switch from "react-switch";
import Periods from "./Periods";
import CountryList from "../../../components/CountryList";

class ChartmetricOptions extends Component {
  constructor(props) {
    super(props);
    this.state = { isOptionsOpen: false };
  }
  unlink = async () => {
    const data = { id: this.props.id };
    try {
      const config = {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" }
      };
      const result = await fetch(
        `${process.env.REACT_APP_SOME_API_URL}/api/chartmetric/deassociate`,
        config
      );
      if (result.status === 200) {
        this.props.unlinked();
      }
    } catch (err) {
      console.error(err);
    }
  };

  toggle = async () => {
    this.setState(prev => ({ isOptionsOpen: !prev.isOptionsOpen }));
  };

  handlePeriodChange = async event => {
    const period = event.target.value;
    localStorage.setItem("chartmetric-period", period);
    this.props.periodChange(period);
  };

  handleGTrendsPlatformChange = useYoutube => {
    localStorage.setItem("google-trends-use-youtube", useYoutube);
    this.props.gTrendsPlatformChange(useYoutube);
  };

  handleCountryChange = country => {
    localStorage.setItem("google-trends-country", country);
    this.props.countryChange(country);
  };

  render() {
    const { isOptionsOpen } = this.state;
    const { period, useYoutube, country } = this.props;
    return (
      <div>
        <div
          className="chartmetricOptionsButton"
          onClick={this.toggle.bind(this)}
        >
          <img
            src="https://image.flaticon.com/icons/svg/25/25442.svg"
            alt="Options"
            style={{
              width: "26px",
              heigth: "26px"
            }}
          />
        </div>
        <div className={`chartmetricOptions ${isOptionsOpen && "visible"}`}>
          <div className="chartmetricOption">
            <button onClick={this.unlink.bind(this)} className="form-control">
              <img
                src="http://cdn.onlinewebfonts.com/svg/img_335091.png"
                alt="unlink"
                style={{
                  width: "25px",
                  heigth: "25px"
                }}
              />
              <span> Unlink</span>
            </button>
          </div>
          <div className="chartmetricOption">
            <select
              name="cmTimeSelector"
              value={period}
              onChange={this.handlePeriodChange.bind(this)}
              className="form-control"
              id="cmTimeSelector"
            >
              {Periods.map(period => {
                return (
                  <option key={period.value} value={period.value}>
                    {period.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="chartmetricOption">
            <CountryList
              value={country}
              onChange={this.handleCountryChange.bind(this)}
            />
          </div>
          <div className="chartmetricOption">
            <span className="">Google</span>
            <Switch
              onChange={this.handleGTrendsPlatformChange.bind(this)}
              checked={useYoutube}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#ff0000"
              offColor="#4285f4"
            />
            Youtube
          </div>
        </div>
      </div>
    );
  }
}

export default ChartmetricOptions;

import React, {Component} from "react";
import Title from "../../components/Title";
import EventsList from "./Components/EventsList";

const moment = require('moment');
require('moment-timezone');

class OnSale extends Component {
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            loading: true
        };

        this.getEvents = (when) => {
            return fetch(`/api/bowman/events/onsale?when=${when}`, {method: "GET"})
                .then(response => response.json())
                .then(data => {
                    data.events.forEach(event => {
                        if (moment(event.date).isValid())
                            event.date = moment(event.date).format("dddd, MMMM Do YYYY, h:mm:ss a")
                        event.onsale_date = moment(event.onsale_date).tz('America/Toronto').format('MM/DD/YYYY HH:mm');
                    });

                    data.events.sort((a, b) => {
                        if (a.onsale_date === b.onsale_date)
                            return a.event.localeCompare(b.event);
                        return a.onsale_date.localeCompare(b.onsale_date);
                    });

                    this.state.lastFetch = moment(data.updated_at).format('HH:mm');
                    return data.events;
                });
        }
    }

    async componentDidMount() {
        const events = await this.getEvents('');

        this.setState({
            events,
            loading: false,
            hideButtons: false,
            when: '',
            ds: false,
            group_by: 'hours'
        });
    }

    render() {
        return (
            <div className={"with-padding"}>
                <Title title={"On Sale Events"} icon={"menu"}>
                    <small>
                        {this.state.loading ? 'Loading...' : (`Last update: ${this.state.lastFetch}`)}
                    </small>
                </Title>

                <button
                    style={{marginRight: 10}}
                    className={`btn ${this.state.when === 'past' ? 'btn-primary' : 'btn-default'}`}
                    onClick={async () => {
                        const events = await this.getEvents('past');
                        this.setState({events, hideButtons: true, when: 'past', ds: false});
                    }}>Past
                </button>

                <button
                    style={{marginRight: 10}}
                    className={`btn ${this.state.when === '' ? 'btn-primary' : 'btn-default'}`}
                    onClick={async () => {
                        const events = await this.getEvents('');
                        this.setState({events, hideButtons: false, when: '', ds: false, group_by: 'hours'});
                    }}>Active
                </button>

                <button
                    style={{marginRight: 10}}
                    className={`btn ${this.state.when === 'future' ? 'btn-primary' : 'btn-default'}`}
                    onClick={async () => {
                        const events = await this.getEvents('future');
                        this.setState({events, hideButtons: false, when: 'future', ds: false, group_by: 'days'});
                    }}>Future
                </button>

                <button
                    style={{marginLeft: 30}}
                    className={`btn ${this.state.ds ? 'btn-primary' : 'btn-default'}`}
                    onClick={async () => {
                        const events = await this.getEvents(this.state.when);

                        if (!this.state.ds) {
                            for (let i = 0; i < events.length; i++) {
                                if (!events[i].seller.includes('(TMUSA)') && !events[i].seller.includes('(TMCA)') && !events[i].seller.includes('(LIVN)')) {
                                    events.splice(i, 1);
                                    i--;
                                }
                            }
                        }

                        this.setState({
                            events,
                            hideButtons: this.state.hideButtons,
                            when: this.state.when,
                            ds: !this.state.ds
                        });
                    }}>DS
                </button>

                <hr/>

                <EventsList
                    id={`on-sale`}
                    loading={this.state.loading}
                    events={this.state.events}
                    hideButtons={this.state.hideButtons}
                    group_by={this.state.group_by}
                    type={"onsale"}
                />
            </div>
        );
    }
}

export default OnSale;

import React, { Component } from "react";
import NumberFormatter from "../../../utils/NumberFormatter";

class Information extends Component {
  constructor(props) {
    super(props);
    this.state = { readMore: true };
  }

  getDescription = () => {
    let desc = this.props.cmArtist.description;
    if (!!desc) {
      desc = desc.replace(/(<[^>]+>)/g, "");
    }
    return desc;
  };

  readMoreClick = () => {
    this.setState(prev => ({ readMore: !prev.readMore }));
  };

  render() {
    const { name, cm_artist_rank, id } = this.props.cmArtist;
    const { readMore } = this.state;
    return (
      <div className="informationContainer">
        <h3>
          <a href={`https://chartmetric.io/artist?id=${id}`}>{name}</a>
          {!!cm_artist_rank &&
            ` | CPP Rank: ${NumberFormatter.numberWithSpaces(cm_artist_rank)}`}
        </h3>
        <div
          id="artistInformationDescription"
          className={readMore ? "collapsed" : "expanded"}
        >
          {this.getDescription()}
        </div>
        <div
          onClick={this.readMoreClick.bind(this)}
          style={{ cursor: "pointer" }}
        >
          Read {readMore ? "more" : "less"}
        </div>
      </div>
    );
  }
}

export default Information;

import React, { Component } from "react";
import ScriptTag from "react-script-tag";
import StringFormatter from "../../../utils/StringFormatter";
import Periods from "./Periods";

class GoogleTrends extends Component {
  constructor(props) {
    super(props);
    this.containerMapId = "GTrendMapWidget";
    this.containerGraphId = "GTrendGraphWidget";
  }

  generateScriptTag = () => {
    const { country, name, period, useYoutube } = this.props;
    const artistSimplified = StringFormatter.normalizeArtistName(name);
    const gtPeriod = Periods.find(p => p.value === period).gtvalue;

    const script = (
      <ScriptTag type="text/javascript">
        {`var myNode = document.getElementById("${this.containerMapId}");
      while (myNode.firstChild) { myNode.removeChild(myNode.firstChild); }
      myNode = document.getElementById("${this.containerGraphId}");
      while (myNode.firstChild) { myNode.removeChild(myNode.firstChild); }

      trends.embed.renderExploreWidgetTo(
        document.getElementById("${this.containerGraphId}"),
        'TIMESERIES',
        {"comparisonItem":[{"keyword":"${artistSimplified}","geo":"${country}","time":"${gtPeriod}"}],"category":0,"property":"${
          useYoutube ? "youtube" : ""
        }"},
        {"exploreQuery":"q=${artistSimplified}${!!country &&
          `&geo=${country}`}${useYoutube &&
          "&gprop=youtube"}&date=${gtPeriod}","guestPath":"https://trends.google.com:443/trends/embed/"}
        );
        trends.embed.renderExploreWidgetTo(
          document.getElementById("${this.containerMapId}"),
        'GEO_MAP',
        {"comparisonItem":[{"keyword":"${artistSimplified}","geo":"${country}","time":"${gtPeriod}"}],"category":0,"property":"${
          useYoutube ? "youtube" : ""
        }"},
        {"exploreQuery":"q=${artistSimplified}${!!country &&
          `&geo=${country}`}${useYoutube &&
          "&gprop=youtube"}&date=${gtPeriod}","guestPath":"https://trends.google.com:443/trends/embed/"}
        );`}
      </ScriptTag>
    );
    return script;
  };

  render() {
    return (
      <div className={"gTrendsContainer"}>
        {this.generateScriptTag()}
        <div
          id={this.containerGraphId}
          style={{ width: "400px", height: "300px" }}
        />
        <div id={this.containerMapId} style={{ width: "400px" }} />
      </div>
    );
  }
}

export default GoogleTrends;

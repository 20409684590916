import React, { Component } from "react";
import ReactTable from "react-table";
import Loading from "../../../components/Loading";

class CppTable extends Component {
  render() {
    const { loading, artists } = this.props;
    console.log("artists", artists);
    const columns = [
      {
        Header: "Artist Name",
        accessor: "ArtistName",
        width: 160,
        style: this.columnTextStyle
      },
      {
        Header: "CPP Today",
        accessor: "CPPToday",
        width: 160,
        style: this.columnTextStyle
      },
      {
        Header: "Today At",
        accessor: "TodayAt",
        width: 160,
        style: this.columnTextStyle
      },
      {
        Header: "CPP Yesterday",
        accessor: "CPPYesterday",
        width: 160,
        style: this.columnTextStyle
      },
      {
        Header: "Yesterday At",
        accessor: "YesterdayAt",
        width: 160,
        style: this.columnTextStyle
      },
      {
        Header: "Diff Position",
        accessor: "DiffPosition",
        width: 160,
        style: this.columnTextStyle
      }
    ];
    return (
      <ReactTable
        style={{ minHeight: "calc(95vh - 59px)", height: "0px" }}
        loading={loading}
        loadingText={<Loading />}
        noDataText="No tweet found"
        columns={columns}
        data={artists || []}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toUpperCase()
            .indexOf(filter.value.toUpperCase()) > -1
        }
        defaultPageSize={100}
      />
    );
  }
}

export default CppTable;

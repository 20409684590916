export default prefix => [
  // {
  //   label: "Show Curve",
  //   name: "curve",
  //   type: "select",
  //   id: "curve",
  //   placeHolder: "",
  //   defaultValue: localStorage.getItem(`${prefix}-curve`) || "yes",
  //   error: null,
  //   options: [
  //     {
  //       label: "Yes",
  //       value: "yes"
  //     },
  //     {
  //       label: "No",
  //       value: "no"
  //     }
  //   ]
  // },
  // {
  //   label: "Begin at Zero",
  //   name: "beginAtZero",
  //   type: "select",
  //   id: "beginAtZero",
  //   placeHolder: "",
  //   defaultValue: localStorage.getItem(`${prefix}-beginAtZero`) || "no",
  //   error: null,
  //   options: [
  //     {
  //       label: "Yes",
  //       value: "yes"
  //     },
  //     {
  //       label: "No",
  //       value: "no"
  //     }
  //   ]
  // },
  {
    label: "Days before Presale",
    name: "presaleDays",
    type: "text",
    id: "presaleDays",
    placeHolder: "",
    defaultValue: localStorage.getItem(`${prefix}-presaleDays`) || "7",
    error: null
  },

  {
    label: "Graph per Line",
    name: "graphPerLine",
    type: "select",
    id: "graphPerLine",
    placeHolder: "",
    defaultValue: localStorage.getItem(`${prefix}-graphPerLine`) || "2",
    error: null,
    options: [
      {
        label: "1",
        value: "1"
      },
      {
        label: "2",
        value: "2"
      }
    ]
  },
  {
    label: "Graph per Page",
    name: "graphPerPage",
    type: "text",
    id: "graphPerPage",
    placeHolder: "",
    defaultValue: localStorage.getItem(`${prefix}-graphPerPage`) || "30",
    error: null
  }
];

import React, { Component } from "react";
import CppGraph from "./CppGraph";

class CppGraphContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.serverUrl = process.env.REACT_APP_SOME_API_URL;
  }

  componentDidMount() {
    this.fetchCppData();
  }

  fetchCppData = async () => {
    this.setState({ loading: true });
    console.log('artists Graph');
    const response = await fetch(
      `${this.serverUrl}/api/chartmetric/topArtists`
    );
    const artists = await response.json();
    console.log('artists Graph', artists);
    this.setState({ artists, loading: false });
  };

  render() {
    const { artists, loading } = this.state;
    return (
      <CppGraph loading={loading} artists={artists} />
    );
  }
}

export default CppGraphContainer;

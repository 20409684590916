import React from "react";
import ReactDOM from "react-dom";
import "react-table/react-table.css";
import "./css/bootstrap.css";
import "./css/index.css";
import "./css/stroke-icons/style.css";
import "./css/pe-icons/helper.css";
import "./css/pe-icons/pe-icon-7-stroke.css";
import "./css/animate.css";
import "./css/font-awesome.min.css";
import "./css/artist.css";

import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import LogRocket from "logrocket";
import setupLogRocketReact from 'logrocket-react';

LogRocket.init('imngox/sos-frontend');
setupLogRocketReact(LogRocket);

ReactDOM.render(<App/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

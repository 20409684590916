import React, { Component } from "react";
import ReactModal from "react-modal";
import Router from "./Router";

ReactModal.setAppElement('#root');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return <Router />;
  }
}

export default App;

import React, { Component } from "react";
import DailyNewFollowersPuck from "./DailyNewFollowersPuck";
import DailyNewFollowersChart from "./DailyNewFollowersChart";
import DailyNewFolowersCSVDownloader from "./DailyNewFolowersCSVDownloader";
import Loading from "../../../components/Loading";

class ChartsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.getDailyNewFollowers();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async getDailyNewFollowers() {
    const id = this.props.cmArtist.id;
    if (!!id) {
      this.setState({ loading: true });
      const response = await fetch(
        `${
          process.env.REACT_APP_SOME_API_URL
        }/api/chartmetric/dailyNewFollowers?id=${id}`
      );
      const dailyNewFollowers = await response.json();
      if (!!this.mounted) {
        this.setState({ dailyNewFollowers, loading: false });
      }
    }
  }

  render() {
    const { dailyNewFollowers, loading } = this.state;
    const { period, artist, cmArtist } = this.props;
    return (
      <div>
        {!!dailyNewFollowers && (
          <div>
            <div id="someDownloadLink">
              <DailyNewFolowersCSVDownloader
                data={dailyNewFollowers}
                artist={artist}
                cmArtist={cmArtist}
              />
            </div>
            <div id={"chartsContainer"}>
              <div style={{ flexGrow: "1", flexBasis: "50%" }}>
                <DailyNewFollowersChart
                  data={dailyNewFollowers}
                  field="value"
                  yAxisTitle="Total Followers"
                  period={period}
                />
              </div>
              <div style={{ flexGrow: "1", flexBasis: "50%" }}>
                <DailyNewFollowersChart
                  data={dailyNewFollowers}
                  field="daily_diff"
                  nameSuffix="diff"
                  yAxisTitle="Diff Followers"
                  period={period}
                />
              </div>
            </div>
            <div id={"dailyNewFollowersContainer"}>
              {dailyNewFollowers.map(dnf => (
                <DailyNewFollowersPuck key={dnf.info.name} data={dnf} />
              ))}
            </div>
          </div>
        )}
        {!!loading && <Loading />}
      </div>
    );
  }
}

export default ChartsContainer;

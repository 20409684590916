import React, {Component} from "react";
import Topbar from "./topbar/Topbar";
import Sidebar from "./sidebar/Sidebar";
import {withRouter} from "react-router-dom";

class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navToggled: localStorage.getItem("navToggled") === "true",
        };
    }

    onToggleMenu = () => {
        localStorage.setItem("navToggled", (!this.state.navToggled).toString());
        this.setState({
            navToggled: !this.state.navToggled
        });
    };

    render() {
        return (
            <div className={this.state.navToggled ? "nav-toggle" : null}>
                <Topbar toggleMenu={this.onToggleMenu} />
                <Sidebar/>
                {this.props.children}
            </div>
        );
    }
}

export default withRouter(Layout);

import React, { Component } from "react";
import moment from "moment-timezone";

class QuickInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.linkIcon = {
      instagram: "instagram",
      wikipedia: "wikipedia-w",
      website: "browser",
      twitter: "twitter",
      youtube: "youtube",
      spotify: "spotify",
      itunes: "itunes",
      facebook: "facebook",
      lastfm: "lastfm"
      // youtubeforartist: "youtube",
      // musicbrainz: "musicbrainz",
      // shazam: "shazam",
      // genius: "genius",
      // deezer: "deezer",
      // songkick: "songkick",
      // bandsintown: "bandsintown",
    };
  }

  componentDidMount() {
    this.fetchLastestRelease();
  }

  async fetchLastestRelease() {
    const id = this.props.cmArtist.id;
    if (!!id) {
      const response = await fetch(
        `${
          process.env.REACT_APP_SOME_API_URL
        }/api/chartmetric/latestRelease?id=${id}`
      );
      const releases = await response.json();
      if (!!releases && releases.length > 0) {
        this.setState({ release: releases[0] });
      }
    }
  }

  getTags(tags) {
    return tags
      .slice(0, 3)
      .map(tag => <span key={`tag-${tag.id}`}>{tag.name}, </span>);
  }

  showLatestRelease() {
    const { release } = this.state;
    const releaseDate = moment(release.release_dates[0]).format(
      "MMMM DD, YYYY"
    );
    return <span>{`${release.name} (${releaseDate})`}</span>;
  }

  makeLink(url) {
    return (
      !!this.linkIcon[url.domain] && (
        <a
          key={url.domain}
          href={url.url[0]}
          alt={this.linkIcon[url.domain]}
          style={{ margin: "2px" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className={`fab fa-${this.linkIcon[url.domain]} fa-2x`} />
        </a>
      )
    );
  }

  render() {
    const { cmArtist, cmUrls } = this.props;
    const { release } = this.state;
    return (
      <div className={"quickInfoContainer"}>
        <div>
          <img
            src={cmArtist.image_url}
            alt={cmArtist.name}
            style={{ width: "190px" }}
          />
        </div>
        <div style={{width: "200px"}}>
          {!!cmArtist.hometown_city && (
            <div>
              <b>Hometown: </b>
              {cmArtist.hometown_city}
            </div>
          )}
          {!!cmArtist.tags && (
            <div>
              <b>Genre: </b>
              {this.getTags(cmArtist.tags)}
            </div>
          )}
          {!!release && !!release.release_dates && (
            <div>
              <b>Lastest Release: </b>
              {this.showLatestRelease()}
            </div>
          )}
          {!!cmUrls && (
            <div>
              <b>Links: </b>
              {cmUrls.map(url => (!!url.url ? this.makeLink(url) : ""))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default QuickInfo;

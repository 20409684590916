import React from 'react';

const PageTitle = ({children, icon, title}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="view-header">
                    <div className="header-icon">
                        <i className={`pe page-header-icon pe-7s-${icon}`} />
                    </div>
                    <div className="header-title">
                        <h3>{title}</h3>
                        {children}
                    </div>
                </div>
                <hr />
            </div>
        </div>
    );
};

export default PageTitle;
import React, { Component } from "react";

class ChartmetricSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  selectArtist = async (id, cmId) => {
    this.props.onSelecting(id);
    const data = { id: id, chartmetricId: cmId };
    try {
      const config = {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" }
      };
      const result = await fetch(
        `${process.env.REACT_APP_SOME_API_URL}/api/chartmetric/associate`,
        config
      );
      if (result.status === 200) {
        this.props.onSelected(id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  singleArtist = (a, id) => {
    return (
      <div
        key={a.id}
        onClick={this.selectArtist.bind(this, id, a.id)}
        style={{
          display: "flex",
          margin: "15px",
          border: "3px solid black",
          background: "#565b6b",
          minWidth: "400px"
        }}
      >
        <div style={{ width: "90px", height: "90px" }}>
          <img
            src={a.image_url}
            alt={a.name}
            style={{ maxWidth: "80px", maxHeight: "90px", padding: "5px" }}
          />
        </div>
        <div>
          <h4>{a.name}</h4>
          {!!a.hometown_city && <div>Hometown: {a.hometown_city}</div>}
          {!!a.sp_followers && <div>Spotify Followers: {a.sp_followers}</div>}
        </div>
      </div>
    );
  };

  render() {
    const { artists, artist } = this.props;
    return (
      <div>
        <h3 style={{ margin: "15px" }}>{artist.name}</h3>
        {artists.map(a => this.singleArtist(a, artist.id))}
      </div>
    );
  }
}

export default ChartmetricSelector;

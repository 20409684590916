import React, {Component} from "react";
import SingleGraph from "./SingleGraph";

class GraphContainer extends Component {
    constructor(props) {
        super(props);

        this.onGraphChange = this.onGraphChange.bind(this);

        this.state = {
            currentGraphIndex: -1,
        };
    }

    onGraphChange(idx) {
        this.setState({
            currentGraphIndex: idx,
        });
    }

    render() {
        return (
            <div>
                {this.state.currentGraphIndex > -1 ? null : (
                    <SingleGraph
                        {...this.props}
                        previousId={null}
                        fetchEvents={this.props.fetchEvents}
                    />
                )}

            </div>
        );
    }
}

export default GraphContainer;

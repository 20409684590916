import React, {Component} from "react";
import ReactPaginate from "react-paginate";
import Loading from "../../../components/Loading";
import GraphContainer from "./GraphContainer";


class GraphList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            events,
            loading,
            graphSettings,
            pageCount,
        } = this.props;

        if (loading) {
            return <Loading/>;
        }

        const graphs = [];

        for (let i = 1; i <= events.length; i += 2) {
            const graph1 = (
                <GraphContainer
                    settings={graphSettings}
                    key={events[i - 1].id}
                    event={events[i - 1]}
                    fetchEvents={this.props.fetchEvents}
                />
            );

            let graph2 = null;
            if (i < events.length) {
                graph2 = (
                    <GraphContainer
                        settings={graphSettings}
                        key={events[i].id}
                        event={events[i]}
                        fetchEvents={this.props.fetchEvents}
                    />
                );
            }

            graphs.push(
                <div key={`div-${graph1.key}`} className="row">
                    {graph1}
                    {graph2}
                </div>
            );
        }
        return (
            <div className="row">
                {graphs.map((event) => {
                    return event;
                })}

                <div
                    style={{marginTop: 40, textAlign: "center"}}
                    className="col-lg-12"
                >
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        forcePage={this.props.currentPage}
                        onPageChange={this.props.onPageChange}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </div>
            </div>
        );
    }
}

export default GraphList;

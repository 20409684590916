import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment-timezone";
import "chartjs-plugin-annotation";
import NumberFormatter from "../../../utils/NumberFormatter";

class DailyNewFollowersChart extends Component {
  generateLabels = () => {
    const { period } = this.props;
    return Array.apply(null, { length: period }).map(daysToAdd =>
      moment().add(-daysToAdd, "days")
    );
  };

  filterFollowers = data => {
    const { period } = this.props;
    return data.filter((value, index, arr) => {
      value.timestp = moment(value.timestp, "ddd MMM DD YYYY");
      const dayDiff = moment().diff(value.timestp, "day");
      if (dayDiff <= period) {
        if (period > 90) {
          return value.timestp.weekday() === moment().weekday();
        } else if (period > 31) {
          return value.timestp.dayOfYear() % 2 === 0;
        } else {
          return value.timestp;
        }
      }
      return false;
    });
  };

  generateDataSet = () => {
    const { data } = this.props;
    const datasets = [];
    const labels = this.generateLabels(data);
    data.forEach(platform => {
      let field = null;
      const properties = Object.keys(platform.data);
      if (properties.includes("followers")) {
        field = "followers";
      } else if (properties.includes("likes")) {
        field = "likes";
      } else if (properties.includes("subscribers")) {
        field = "subscribers";
      }
      if (!!field) {
        let values = platform.data[field];
        values = this.filterFollowers(values);
        if (!!values && values.length > 0) {
          const nameSuffix = !!this.props.nameSuffix
            ? ` ${this.props.nameSuffix}`
            : "";
          const name = `${platform.info.name} ${field}${nameSuffix}`;
          const backgroundColor = platform.info.backgroundColor;
          datasets.push({
            fill: false,
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: backgroundColor,
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            label: name,
            data: values.map(f => {
              return { x: f.timestp, y: f[this.props.field] };
            }),
            yAxisID: "y-axis-1"
          });
        }
      }
    });

    return {
      labels,
      datasets
    };
  };

  generateOptions = () => {
    return {
      maintainAspectRatio: true,
      responsive: true,
      tooltips: {
        mode: "x",
        intersect: true,
        position: "average",
        callbacks: {
          beforeBody: (items, data) => {
            items.forEach(item => {
              item.yLabel = NumberFormatter.numberWithSpaces(item.yLabel);
            });
          }
        }
      },
      scales: {
        xAxes: [
          {
            id: "x-axis-1",
            type: "time",
            distribution: "linear",
            display: true,
            spanGaps: true,
            time: {
              tooltipFormat: "MMM DD",
              minUnit: "day"
            },
            scaleLabel: {
              display: true,
              labelString: "Time"
            }
          }
        ],
        yAxes: [
          {
            id: "y-axis-1",
            type: "linear",
            position: "left",
            display: true,
            ticks: {
              beginAtZero: false,
              callback: (value, index, values) => {
                return NumberFormatter.shorten(value, 1);
              }
            },
            scaleLabel: {
              display: true,
              labelString: this.props.yAxisTitle,
              fontColor: "#ffffff",
              fontSize: 11
            }
          }
        ]
      }
    };
  };

  render = () => {
    const data = this.generateDataSet();
    const options = this.generateOptions();
    return <Line data={data} options={options} />;
  };
}

export default DailyNewFollowersChart;

import React, {Component} from "react";
import Title from "../../components/Title";
import EventList from "./Components/EventList";

class index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            loading: true,
            notified: false
        };

        // Get the list of events from the API
        this.fetchEvents = async () => {
            const response = await fetch(`/api/concertweek/list`, {method: "GET"});
            let events = await response.json();

            events = events.filter(event => event.notification_sent == this.state.notified);

            this.setState({
                events,
                loading: false
            });
        }

        // Toggle the notified state, and fetch the events again
        this.toggleNotified = () => {
            this.setState({
                notified: !this.state.notified
            }, () => {
                this.fetchEvents().then();
            });
        }
    }

    // Fetch the events on mount, and then every 10 seconds
    async componentDidMount() {
        this.setState({
            loading: true
        });
        await this.fetchEvents();
        this.interval = setInterval(async () => {
            await this.fetchEvents();
        }, 10000);
    }

    // Stop the interval on unmount
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    // Render the component
    render() {
        return (
            <div className={"with-padding"}>
                <Title title={"Concert Week Notify"} icon={"menu"}>
                    <small>
                        Today
                    </small>
                </Title>

                {/* Button to change if show only notified events */}
                <button
                    style={{marginRight: 10}}
                    className={`btn ${this.state.notified ? 'btn-primary' : 'btn-default'}`}
                    onClick={async () => {
                        this.toggleNotified();
                    }}>
                    {this.state.notified ? <i className="fa fa-bell"/> :
                        <i className="fa fa-bell-o"/>}
                    &nbsp;
                    Notified
                </button>

                <hr/>

                {/* Event list */}
                <EventList id={`wc-25`}
                           events={this.state.events} loading={this.state.loading}
                           fetchEvents={this.fetchEvents}
                />
            </div>
        );
    }
}

export default index;

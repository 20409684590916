import React, {Component} from "react";
import {stringify} from "query-string";
import GraphList from "./Components/GraphList";
import Filters from "./Components/Filters";
import filterInputs from "./Filters/EventFilter";
import graphFilterInputs from "./Filters/GraphFilter";
import Title from "../../components/Title";

const getInitialState = (id) => {
    return {
        loading: true,
        pageCount: 0,
        events: [],
        filterInputs: filterInputs(id),
        graphFilterInputs: graphFilterInputs(id),
        currentPage: 0,
        filters: {
            graphPerLine: localStorage.getItem(`${id}-graphPerLine`) || "2",
            curve: localStorage.getItem(`${id}-curve`) || "yes",
            event_name: localStorage.getItem(`${id}-event_name`) || "",
            notification_sent: localStorage.getItem(`${id}-notification_sent`) || "",
            sg_getin: localStorage.getItem(`${id}-sg_getin`) || "",
            sg_listings: localStorage.getItem(`${id}-sg_listings`) || "",
            hidden: localStorage.getItem(`${id}-hidden`) || "",
            sg_delta: localStorage.getItem(`${id}-sg_delta`) || "",
            graphPerPage: localStorage.getItem(`${id}-graphPerPage`) || 30,
            after_date: localStorage.getItem(`${id}-after_date`) || "",
        },
    };
};

class ActiveCW extends Component {
    constructor(props) {
        super(props);
        this.state = getInitialState(this.props.id);
        this.fetchGraphData = this.fetchGraphData.bind(this);
        this.fetchEvents = this.fetchEvents.bind(this);
    }

    onChangedFilters = async (filters) => {
        this.setTimer();
        return new Promise((resolve, reject) => {
            for (const key in filters) {
                localStorage.setItem(`${this.props.id}-${key}`, filters[key]);
            }

            this.setState(
                {
                    currentPage: 0,
                    loading: true,
                    filters: {
                        ...this.state.filters,
                        ...filters,
                    },
                },
                async () => {
                    await this.fetchGraphData();
                    resolve();
                }
            );
        });
    };


    async fetchApi(url) {
        const response = await fetch(url, {
            method: "GET",
        });
        return response.json();
    }

    async fetchGraphData(showLoading = true) {
        if (showLoading)
            this.setState({loading: showLoading});

        const query = {
            offset:
                parseInt(this.state.currentPage) *
                parseInt(this.state.filters.graphPerPage),
            limit: parseInt(this.state.filters.graphPerPage),
            filters: JSON.stringify(this.state.filters),
            sort: this.state.filters.sortBy,
        };

        console.log(stringify(query));

        let response = await this.fetchApi(`/api/concertweek/active?${stringify(query)}`);

        if (response.events.length <= 0) {

            const filters = {...this.state.filters};
            filters.sale_date = "all";
            query.filters = JSON.stringify(filters);
            response = await this.fetchApi(`/api/concertweek/active?${stringify(query)}`);
        }

        const {events, numberOfRows} = response;

        this.setState({
            pageCount: Math.ceil(
                parseInt(numberOfRows) / parseInt(this.state.filters.graphPerPage)
            ),
            loading: false,
            events,
            filterInputs: this.state.filterInputs.map((filter) => {
                return filter;
            }),
        });
    }

    fetchEvents() {
        this.fetchGraphData(false).then();
    }

    setTimer() {
        clearInterval(this.timer);
        clearInterval(this.timerActiveWatch);
        this.timer = null;
        this.timerActiveWatch = null;
        this.timer = setInterval(() => this.fetchGraphData(false), 30000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
        clearInterval(this.timerActiveWatch);
        this.timerActiveWatch = null;
    }

    async componentDidMount() {
        await this.fetchGraphData();
        this.setTimer();
    }

    handlePageClick = ({selected}) => {
        this.setState(
            {
                currentPage: selected,
            },
            () => {
                this.fetchGraphData();
            }
        );
    };

    render() {
        return (
            <div className={"with-padding"}>
                <Title title={"Graphs"} icon={"graph3"}>
                    <small>
                        Active Concert Week Events
                    </small>
                </Title>

                <div className="row">
                    <Filters
                        title={"Filters"}
                        inputs={this.state.filterInputs}
                        onChangedFilters={this.onChangedFilters}
                    />
                    <Filters
                        title={"Graph settings"}
                        inputs={this.state.graphFilterInputs}
                        onChangedFilters={this.onChangedFilters}
                    />
                </div>

                <GraphList
                    onPageChange={this.handlePageClick}
                    perPage={this.state.filters.graphPerPage}
                    currentPage={this.state.currentPage}
                    pageCount={this.state.pageCount}
                    loading={this.state.loading}
                    events={this.state.events}
                    watchEvents={this.state.watchEvents}
                    graphSettings={{
                        ...this.state.filters,
                    }}
                    fetchEvents={this.fetchEvents}
                />
            </div>
        );
    }
}

export default ActiveCW;

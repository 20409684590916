import React from "react";

const Loading = () => {
  return (
    <div className="loader-example">
      <div className="loader-spin" />
    </div>
  );
};

export default Loading;

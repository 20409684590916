import React, { Component } from "react";
import ReactTable from "react-table";
import Loading from "../../components/Loading";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import NumberFormatter from "../../utils/NumberFormatter";

class TweetList extends Component {
  constructor(props) {
    super(props);
    this.tweetTextFilter = {
      presale: ["presale", "pre-sale", "pre sale"],
      onsale: ["onsale", "on-sale", "on sale"],
      ticket: ["ticket", "tickets", "tix"],
      tour: ["tour"],
      soldout: ["sold out", "sold-out", "soldout"],
      addshow: ["added shows", "add shows", "added show", "add show"]
    };
    this.dateFilterOptions = [
      { value: "1", label: "Last 24h" },
      { value: "3", label: "Last 72h" },
      { value: "7", label: "Last 7d" }
    ];
    this.keywordFilter = [
      { value: 0, text: "None", className: "none" },
      { value: 1, text: "Presale", className: "presale" },
      { value: 2, text: "Onsale", className: "onsale" },
      { value: 3, text: "Ticket", className: "ticket" },
      { value: 4, text: "Tour", className: "tour" },
      { value: 5, text: "Soldout", className: "soldout" },
      { value: 6, text: "Add show", className: "addshow" }
    ];
    this.state = {
      loading: true
    };
    this.columnTextStyle = { overflowWrap: "break-word", whiteSpace: "unset" };
  }

  async componentDidMount() {
    this.loadLast7Days();
  }

  loadLast7Days = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SOME_API_URL}/api/twitter/last7days`,
      { method: "GET" }
    );
    const tweets = await response.json();
    this.addMissingInfo(tweets);
    this.setState({
      tweets,
      loading: false
    });
  };

  addMissingInfo = tweets => {
    for (let index = 0; index < tweets.length; index++) {
      const tweet = tweets[index];
      this.addKeywords(tweet);
      this.addFavoriteFollowerRatio(tweet);
    }
  };

  addKeywords = tweet => {
    const t = tweet.text;
    tweet.keywords = [];
    let index = 0;
    for (const key in this.tweetTextFilter) {
      if (this.tweetTextFilter.hasOwnProperty(key)) {
        const keywords = this.tweetTextFilter[key];
        index++;
        if (keywords.some(i => t.toUpperCase().includes(i.toUpperCase()))) {
          tweet.keywords.push(index);
        }
      }
    }
  };

  addFavoriteFollowerRatio = tweet => {
    tweet.favoriteFollowerRatio = parseFloat(
      ((tweet.favoriteCount / tweet.twitterFollowerAmount) * 100).toFixed(2)
    );
    if (!!tweet.favoriteDiff) {
      tweet.previousFavoriteFollowerRatio = parseFloat(
        ((tweet.favoriteDiff / tweet.twitterFollowerAmount) * 100).toFixed(2)
      );
    }
  };

  getDiffText = (value, diff) => {
    let diffText = diff;
    let className = "value_diff_neg";
    if (!!diff && diff > 0) {
      diffText = `+${diff}`;
      className = "value_diff_pos";
    } else if (diff === 0) {
      className = "";
    }

    return (
      <div>
        <span>{value}</span>
        {(!!diff || diff === 0) && (
          <span>
            (<span className={className}>{diffText}</span>)
          </span>
        )}
      </div>
    );
  };

  render() {
    const { loading, tweets } = this.state;
    const columns = [
      {
        Header: "Artist Name",
        accessor: "name",
        width: 160,
        style: this.columnTextStyle,
        Cell: props => (
          <NavLink to={`/pages/some/artist?q=${props.original.id}`}>
            {props.value} ({NumberFormatter.shorten(props.original.twitterFollowerAmount,0)})
          </NavLink>
        )
      },
      {
        Header: "Date",
        accessor: "date",
        width: 87,
        filterMethod: (filter, row) => {
          const hoursAmount = parseInt(filter.value, 10) * -24;
          const dateToFilter = moment().add(hoursAmount, "hours");
          localStorage.setItem("twitter-list-date-filter", filter.value);
          return moment.tz(row.date, "UTC").isAfter(dateToFilter);
        },
        Filter: ({ filter, onChange }) => {
          if (!filter) {
            filter = {
              value: localStorage.getItem("twitter-list-date-filter") || "3"
            };
            return onChange(filter.value);
          }
          return (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter.value}
            >
              <option value="1">1Day</option>
              <option value="3">3Days</option>
              <option value="7">7Days</option>
            </select>
          );
        },
        Cell: props => (
          <span>
            {!!props &&
              moment
                .tz(props.value, "utc")
                .tz("America/Vancouver")
                .format("(DD) HH:mm")}
          </span>
        ),
        sortMethod: (a, b) => {
          return moment(a).isBefore(b) ? -1 : 1;
        }
      },
      {
        Header: "Fav/Follow%",
        accessor: "favoriteFollowerRatio",
        width: 90,
        Cell: props => {
          return this.getDiffText(
            props.value,
            props.original.previousFavoriteFollowerRatio
          );
        }
      },
      {
        Header: "Favorite",
        accessor: "favoriteCount",
        width: 85,
        Cell: props => {
          return this.getDiffText(props.value, props.original.favoriteDiff);
        }
      },
      {
        Header: "Retweet",
        accessor: "retweetCount",
        width: 85,
        Cell: props => {
          return this.getDiffText(props.value, props.original.retweetDiff);
        }
      },
      {
        Header: "Keywords",
        accessor: "keywords",
        filterable: true,
        sortable: false,
        width: 125,
        style: this.columnTextStyle,
        filterMethod: (filter, row) => {
          localStorage.setItem("twitter-list-keywords-filter", filter.value);
          if (filter.value === "all") {
            return !row.keywords.includes(5);
          }
          for (let index = 0; index < this.keywordFilter.length; index++) {
            const keyword = this.keywordFilter[index].value;
            if (parseInt(filter.value) === keyword) {
              return row.keywords.includes(keyword);
            }
          }
          return row.keywords.length === 0;
        },
        Filter: ({ filter, onChange }) => {
          if (!filter) {
            filter = {
              value:
                localStorage.getItem("twitter-list-keywords-filter") || "all"
            };
            return onChange(filter.value);
          }
          return (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">All</option>
              {this.keywordFilter.map(k => (
                <option key={`${k.value}${k.text}`} value={k.value}>
                  {k.text}
                </option>
              ))}
            </select>
          );
        },
        Cell: props => {
          return (
            <div>
              {this.keywordFilter.map(k => {
                if (props.value.includes(k.value)) {
                  return (
                    <span
                      key={`${k.value}${k.text}`}
                      className={`custom-badge custom-badge-${k.className}`}
                    >
                      {k.text}
                    </span>
                  );
                }
                return "";
              })}
            </div>
          );
        }
      },
      {
        Header: "Tweet",
        accessor: "text",
        style: { overflowWrap: "break-word", whiteSpace: "unset" },
        Cell: props => (
          <a
            href={props.original.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.value}
          </a>
        )
      }
    ];
    return (
      <ReactTable
        style={{ minHeight: "calc(95vh - 59px)", height: "0px" }}
        loading={loading}
        loadingText={<Loading />}
        noDataText="No tweet found"
        columns={columns}
        data={tweets}
        onSortingChange={props => {
          console.log("props", props);
        }}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toUpperCase()
            .indexOf(filter.value.toUpperCase()) > -1
        }
        defaultPageSize={100}
      />
    );
  }
}

export default TweetList;

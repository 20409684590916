import React, { Component } from "react";
import GoogleTrends from "./GoogleTrends";
import Information from "./Information";
import QuickInfo from "./QuickInfo";
import ChartsContainer from "./ChartsContainer";
import ChartmetricOptions from "./ChartmetricOptions";
import Last5Tweets from "./Last5Tweets";
import TopCitiesContainer from "./TopCitiesContainer";

class ArtistContainer extends Component {
  constructor(props) {
    super(props);
    const period = localStorage.getItem("chartmetric-period") || "365";
    const useYoutube = localStorage.getItem("google-trends-use-youtube");
    const country = localStorage.getItem("google-trends-country") || "US";
    this.state = { period, useYoutube: useYoutube === "true", country };
  }

  unlinked = async () => {
    this.props.unlinked();
  };

  handlePeriodChange = async period => {
    await this.setState({ period });
  };

  handleGTrendsPlatformChange = async useYoutube => {
    await this.setState({ useYoutube });
  };

  handleCountryChange = async country => {
    await this.setState({ country });
  };

  render() {
    const { country, period, useYoutube } = this.state;
    const { artist, cmArtist, cmUrls } = this.props;
    return (
      <div className={"artistContainer"}>
        <ChartmetricOptions
          unlinked={this.unlinked.bind(this)}
          periodChange={this.handlePeriodChange.bind(this)}
          gTrendsPlatformChange={this.handleGTrendsPlatformChange.bind(this)}
          countryChange={this.handleCountryChange.bind(this)}
          period={period}
          country={country}
          useYoutube={useYoutube}
          id={artist.id}
        />
        <div style={{ flexGrow: 1 }}>
          <div style={{ display: "flex" }}>
            <QuickInfo cmArtist={cmArtist} cmUrls={cmUrls} />
            <Information cmArtist={cmArtist} artist={artist} />
          </div>
          <ChartsContainer
            artist={artist}
            cmArtist={cmArtist}
            period={period}
          />
          <Last5Tweets twitterAccount={artist.twitterAccount} />
          <TopCitiesContainer cmArtist={cmArtist} />
        </div>
        <GoogleTrends
          name={artist.name}
          period={period}
          country={country}
          useYoutube={useYoutube}
        />
      </div>
    );
  }
}

export default ArtistContainer;

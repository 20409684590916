import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class Sidebar extends Component {
    render() {
        return (
            <aside className="navigation">
                <nav>
                    <ul className="nav luna-nav">
                        <li className="nav-category">
                            Graphs
                        </li>

                        <NavLink to={'/pages/graph/active'} activeClassName='sidebar__link-active'>
                            <li>
                                Active
                            </li>
                        </NavLink>

                        <NavLink to={'/pages/graph/yesterday'} activeClassName='sidebar__link-active'>
                            <li>
                                Yesterday
                            </li>
                        </NavLink>

                        <NavLink to={'/pages/graph/favorite'} activeClassName='sidebar__link-active'>
                            <li>
                                Favorite
                            </li>
                        </NavLink>


                        <li className="nav-category">
                            Concert Week
                        </li>

                        <NavLink to={'/pages/cw/active'} activeClassName='sidebar__link-active'>
                            <li>
                                Graph
                            </li>
                        </NavLink>

                        <NavLink to={'/pages/cw/list'} activeClassName='sidebar__link-active'>
                            <li>
                                List
                            </li>
                        </NavLink>

                        {/* <NavLink to={'/pages/cw/notify'} activeClassName='sidebar__link-active'>
                            <li>
                                Notify
                            </li>
                        </NavLink> */}

                        <li className="nav-category">
                            Lists
                        </li>
                        <NavLink to={'/pages/list/active'} activeClassName='sidebar__link-active'>
                            <li>
                                Active
                            </li>
                        </NavLink>

                        <NavLink to={'/pages/list/past'} activeClassName='sidebar__link-active'>
                            <li>
                                Past
                            </li>
                        </NavLink>

                        <li className="nav-category">
                            Social Media
                        </li>

                        <NavLink to={'/pages/some/tweets'} activeClassName='sidebar__link-active'>
                            <li>
                                Tweets
                            </li>
                        </NavLink>

                        <NavLink to={'/pages/some/artist'} activeClassName='sidebar__link-active'>
                            <li>
                                Artists
                            </li>
                        </NavLink>

                        <li className="nav-category">
                            SOS Data
                        </li>

                        <NavLink to={'/pages/sos/presale'} activeClassName='sidebar__link-active'>
                            <li>
                                Pre Sale
                            </li>
                        </NavLink>

                        <NavLink to={'/pages/sos/onsale'} activeClassName='sidebar__link-active'>
                            <li>
                                On Sale
                            </li>
                        </NavLink>

                        {/* <NavLink to={'/pages/some/cppTable'} activeClassName='sidebar__link-active'>
                            <li>
                                CPP Table
                            </li>
                        </NavLink>

                        <NavLink to={'/pages/some/cppGraph'} activeClassName='sidebar__link-active'>
                            <li>
                                CPP Graph
                            </li>
                        </NavLink> */}
                    </ul>
                </nav>
            </aside>
        );
    }
}

export default Sidebar;
import React from "react";
import {Line} from "react-chartjs-2";
import "chartjs-plugin-annotation";
import moment from "moment-timezone";
import isEqual from "lodash.isequal";
import SendNotifications from "../../cw_notify/Components/SendNotifications";

const generateData = (event) => {
    const reduceFct = (acc, current) => {
        const currDate = moment(current.created_at).utc().format();
        if (!acc.includes(currDate)) {
            return [...acc, currDate];
        }
        return acc;
    };

    const allDates = event.counts.reduce(reduceFct, []);

    const dateLabels = allDates.sort((a, b) => {
        return moment(a).isBefore(moment(b)) ? -1 : 1;
    });

    const countData = [];

    if (event.counts.length > 0) {
        dateLabels.forEach((date) => {
            countData.push(
                event.counts.find((count) => moment(count.created_at).utc().format() === date)
            );
        });
    }

    return {dateLabels, countData};
};

export default class SingleGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetailed: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            !isEqual(nextProps, this.props) ||
            nextState.showDetailed !== this.state.showDetailed
        );
    }

    render() {
        const {
            event,
            settings,
        } = this.props;

        const {dateLabels, countData} = generateData(event);

        const firstDate = moment(dateLabels[0]);
        const lastDate = moment(dateLabels[dateLabels.length - 1]);
        const dayDiff = lastDate.diff(firstDate, "days");

        const showDate = dayDiff >= 1;

        const firstPresalePoint = countData.length > 0 ? countData[0].created_at : '01-01-2000 00:00:00';

        const labels = dateLabels.map((date) => {
            return moment.tz(date, "America/Toronto").seconds(0);
        });

        const data = {
            labels: labels,
            datasets: [
                {
                    fill: false,
                    backgroundColor: "rgba(75,192,192,0.4)",
                    borderColor: "rgba(75,192,192,1)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBorderColor: "rgba(75,192,192,1)",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    label: "All Tickets Count",
                    data: countData.map((c) => (c ? c.latest_count : undefined)),
                    yAxisID: "y-axis-1",
                },
                {
                    fill: false,
                    label: "Concert Week Count",
                    data: countData.map((c) => (c ? c.latest_cw_count : undefined)),
                    backgroundColor: "#f6a821",
                    borderColor: "#f6a821",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBorderColor: "#f6a821",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    hoverBackgroundColor: "#f6a821",
                    hoverBorderColor: "#f6a821",
                    yAxisID: "y-axis-2",
                }
            ],
        };

        const annotation = {
            annotations: [
                {
                    type: "line",

                    // optional drawTime to control layering, overrides global drawTime setting
                    drawTime: "afterDatasetsDraw",

                    // optional annotation ID (must be unique)
                    id: "a-line-1",

                    // set to 'vertical' to draw a vertical line
                    mode: "vertical",

                    // ID of the scale to bind onto
                    scaleID: "x-axis-1",

                    // Data value to draw the line at
                    value: firstPresalePoint ? firstPresalePoint.date : null,

                    // Optional value at which the line draw should end
                    //endValue: 3500,

                    // Line color
                    borderColor: "red",

                    // Line width
                    borderWidth: 2,

                    // Line dash
                    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
                    borderDash: [2, 2],

                    // Line Dash Offset
                    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
                    borderDashOffset: 5,

                    label: {
                        // Background color of label, default below
                        backgroundColor: "rgba(0,0,0,0.8)",

                        // Font family of text, inherits from global
                        fontFamily: "sans-serif",

                        // Font size of text, inherits from global
                        fontSize: 12,

                        // Font style of text, default below
                        fontStyle: "bold",

                        // Font color of text, default below
                        fontColor: "#fff",

                        // Padding of label to add left/right, default below
                        xPadding: 6,

                        // Padding of label to add top/bottom, default below
                        yPadding: 6,

                        // Radius of label rectangle, default below
                        cornerRadius: 6,

                        // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
                        position: "center",

                        // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
                        // For horizontal lines positioned left or right, negative values move
                        // the label toward the edge, and positive values toward the center.
                        xAdjust: 0,

                        // Adjustment along y-axis (top-bottom) of label relative to above number (can be negative)
                        // For vertical lines positioned top or bottom, negative values move
                        // the label toward the edge, and positive values toward the center.
                        yAdjust: 0,

                        // Whether the label is enabled and should be displayed
                        enabled: true,

                        // Text to display in label - default is null. Provide an array to display values on a new line
                        content: "Presale",
                    },
                },
            ],
        };

        let options = {
            annotation: this.state.showDetailed ? annotation : null,
            maintainAspectRatio: true,
            responsive: true,
            elements: {
                line: {
                    tension: settings.curve === "no" ? 0 : 0.5,
                },
            },
            title: {
                display: false,
                text: `(${parseInt(event.sale_type) === 0 ? "PRE" : "PUB"}) ${moment
                    .tz(event.sale_date, "America/Toronto")
                    .format("hh:mm a")} of ${event.name} on ${moment
                    .tz(event.event_date, "America/Toronto")
                    .format("MMM Do YYYY hh:mm a")}`,
                fontColor: "#ffffff",
                fontSize: settings.graphPerLine === "2" ? 14 : 18,
            },
            tooltips: {
                mode: "index",
                intersect: false,
                position: "average",
            },
            legend: {
                labels: {
                    fontColor: "#ffffff",
                    fontSize: settings.graphPerLine === "2" ? 10 : 13,
                },
            },
            scales: {
                xAxes: [
                    {
                        id: "x-axis-1",
                        type: "time",
                        distribution: "series",
                        time: {
                            // Show day and hour if the data is over more than 1 day
                            displayFormats: {
                                hour: "hh:mm a",
                                day: "MMM D hh:mm a"
                            },
                            tooltipFormat: "MMM D hh:mm a",
                            unit: showDate ? "day" : "hour",
                        },
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: "Time",
                        },
                    },
                ],
                yAxes: [
                    {
                        type: "linear",
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        ticks: {
                            beginAtZero: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: "All Tickets Quantity",
                            fontColor: "#ffffff",
                            fontSize: settings.graphPerLine === "2" ? 11 : 14,
                        },
                    },
                    {
                        type: "linear",
                        display: true,
                        position: "right",
                        id: "y-axis-2",
                        ticks: {
                            beginAtZero: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: "Concert Week Quantity",
                            fontColor: "#ffffff",
                            fontSize: settings.graphPerLine === "2" ? 11 : 14,
                        },
                        // grid line settings
                        gridLines: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                    }
                ],
            },
        };

        return (
            <div
                className={`col-sm-12 col-md-12 col-lg-${
                    settings.graphPerLine === "1" ? "12" : "6"
                }`}
                style={{
                    fontSize: settings.graphPerLine === "2" ? 14 : 18,
                    color: "#FFFFFF",
                    marginTop: 40,
                }}
            >

                <div style={{textAlign: "center", maxWidth: 500, margin: "0 auto"}}>
                    {event.id && (
                        <a
                            className={"badge badge-primary"}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`https://bowman.plessinc.com/event/${event.id}`}
                        >
                            BOWMAN
                        </a>
                    )}
                    <a rel="noopener noreferrer" target="_blank"
                       href={`https://www.ticketmaster.com/event/${event.id}`}>
                        {` ${event.name} on ${moment
                            .tz(event.event_date, "UTC")
                            .format("MMM Do YYYY h:mm A")}`}
                    </a>
                    <br/>
                    <span>
                        {event.venue}
                        <strong>
                    {`|  Capacity: ${
                        event.capacity && event.capacity > 0
                            ? event.capacity.toLocaleString(navigator.language, {
                                minimumFractionDigits: 0,
                            })
                            : "Unknown"
                    }`.split("|").join(" | ")}
                    </strong>
                    </span>
                    <br/>
                    <span>
                    <strong>
                    SG Listings:
                    </strong>
                        {`  ${
                            event.sg_listings
                                ? event.sg_listings + "|"
                                : "Unknown |"
                        }`.split("|").join(" | ")}
                        <strong>
                    SG Get In:
                    </strong>
                        {` ${
                            event.sg_getin
                                ? event.sg_getin
                                : "Unknown"
                        }`}
                    </span>
                    <br/>
                    <span>
                    <strong>
                    SG Listings Sold: 
                    </strong>
                        {` ${
                            event.listings_sold_sg
                                ? event.listings_sold_sg + "|"
                                : "Unknown |"
                        }`.split("|").join(" | ")}
                        <strong>
                 SG Tickets Sold:
                    </strong>
                        {`  ${
                            event.tickets_sold_sg
                                ? event.tickets_sold_sg
                                : "Unknown"
                        }`}
                    
                    </span>
                    <br/>


                    <span>
                    <strong>
                    TM MIR: 
                    </strong>
                        {` ${
                            event.tm_mir
                                ? event.tm_mir + "|"
                                : "Unknown |"
                        }`.split("|").join(" | ")}
                        <strong>
                  TM MQR:
                    </strong>
                        {`  ${
                            event.tm_mqr
                                ? event.tm_mqr
                                : "Unknown"
                        }`}
                    
                    </span>
                    <br/>

                    <SendNotifications event={event} graph={true} fetchEvents={this.props.fetchEvents}/>

                    {
                        settings.notification_sent === '1' ?
                            <>
                        <span>
                    <strong>
                    Quantity Purchased: 
                    </strong>
                            {` ${
                                event.qty_purchased !== null ? event.qty_purchased : "N/A"
                            }`}
                    </span>
                                <br/>
                            </> : ""
                    }
                </div>
                <Line data={data} options={options}/>
            </div>
        );
    }
}

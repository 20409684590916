import React, { Component } from "react";
import CppGraphContainer from "./cppGraph/CppGraphContainer";

class CppGraphPage extends Component {
  render() {
    return <CppGraphContainer />;
  }
}

export default CppGraphPage;

import React, { Component } from "react";
import Input from "../../../components/Input";

class Filters extends Component {
  constructor(props) {
    super(props);

    const filters = {};

    props.inputs.forEach(v => {
      if (Array.isArray(v)) {
        v.forEach(k => {
          filters[k.name] = k.defaultValue;
        });
      }
      filters[v.name] = v.defaultValue;
    });

    this.state = {
      loading: false,
      filters,
      hasChanged: false
    };
  }

  onChangeTextInput = evt => {
    this.setState({
      hasChanged: true,
      filters: {
        ...this.state.filters,
        [evt.target.name]: evt.target.value
      }
    });
  };

  onApplyChanges = async evt => {
    evt.preventDefault();
    this.setState({ loading: true, hasChanged: false });
    await this.props.onChangedFilters(this.state.filters);
    this.setState({ loading: false });
  };

  render() {
    if (this.props.inputs.length <= 0) {
      return null;
    }

    return (
      <div className="col-md-6">
        <div className="panel panel-filled panel-c-warning">
          <div className="panel-heading">{this.props.title}</div>
          <div className="panel-body" style={{ display: "block" }}>
            <form className="form-horizontal">
              <div className="form-group">
                {this.props.inputs.map(input => {
                  if (input.name === "hr") {
                    return (
                      <div key={"hr"} className={"col-lg-12"}>
                        <hr />
                      </div>
                    );
                  }

                  let inputs = [input];
                  if (Array.isArray(input)) {
                    inputs = input;
                  }

                  return inputs.map(input => {

                    return (
                      <Input
                        value={this.state.filters[input.name]}
                        onChange={this.onChangeTextInput}
                        key={input.id}
                        {...input}
                      >
                        {({ label, name, error, input }) => {
                          return (
                            <div className={`col-md-${12 / inputs.length}`}>
                              <div
                                className="form-group"
                                style={{ marginLeft: 0, marginRight: 0 }}
                              >
                                <label
                                  htmlFor={name}
                                  className="col-sm-4 col-md-3 control-label"
                                >
                                  {label}
                                </label>
                                <div className="col-sm-8 col-md-9">
                                  {input}

                                  {error ? <small>{error}</small> : null}
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </Input>
                    );
                  });
                })}
              </div>

              <div className="row">
                <div className="col-md-3 col-md-offset-8">
                  <button
                    style={{ marginLeft: 0, marginRight: 0 }}
                    disabled={this.props.loading || !this.state.hasChanged}
                    className="btn btn-w-md btn-accent"
                    onClick={this.onApplyChanges}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Filters;

export default prefix => [
  {
    label: "Event Name",
    name: "event_name",
    type: "text",
    id: "event_name",
    placeholder: "",
    error: null,
    defaultValue: localStorage.getItem(`${prefix}-event_name`) || ""
  },
  {
    label: "Venue",
    name: "venue_name",
    type: "text",
    id: "venue_name",
    placeholder: "",
    error: null,
    defaultValue: localStorage.getItem(`${prefix}-venue_name`) || ""
  },
  {
    label: "City",
    name: "city",
    type: "text",
    id: "city",
    placeholder: "",
    error: null,
    defaultValue: localStorage.getItem(`${prefix}-city`) || ""
  },
  {
    label: "Sale Time",
    name: "sale_start_date",
    type: "select",
    options: [
      {
        label: "All",
        value: "all"
      }
    ],
    id: "sale_start_date",
    placeholder: "",
    error: null,
    defaultValue: localStorage.getItem(`${prefix}-sale_start_date`) || "all"
  },
  {
    label: "Sale Type",
    name: "sale_type",
    type: "select",
    id: "sale_type",
    placeholder: "",
    error: null,
    defaultValue: localStorage.getItem(`${prefix}-sale_type`) || "all",
    options: [
      {
        value: "all",
        label: "All"
      },
      {
        value: "0",
        label: "Presale"
      },
      {
        value: "1",
        label: "Public Sale"
      }
    ]
  },
  {
    name: "hr"
  },
  [
    {
      label: "Sort by",
      name: "sortBy",
      type: "select",
      id: "sortBy",
      placeholder: "",
      error: null,
      defaultValue: localStorage.getItem(`${prefix}-sortBy`) || "event_name",
      options: [
        // {
        //   value: "event_name",
        //   label: "Event Name"
        // },
        // {
        //   value: "venue_name",
        //   label: "Venue"
        // },
        // {
        //   value: "quantity",
        //   label: "Quantity"
        // },
        {
          value: "sales",
          label: "Sales"
        },
        // {
        //   value: "capacity",
        //   label: "Capacity"
        // },
        // {
        //   value: "percentLeft",
        //   label: "Percent Left"
        // },
        {
          value: "quantityDecLast30",
          label: "Qty Decrease # Last 30min"
        },
        {
          value: "quantityDecLastDay",
          label: "Qty Decrease # Today"
        },
        {
          value: "quantityDecRelativeLast30",
          label: "Qty Decrease % Last 30min"
        },
        {
          value: "quantityDecRelativeLastDay",
          label: "Qty Decrease % Last Day"
        },
        {
          value: "salesIncLast30",
          label: "Sale Increase # Last 30min"
        },
        {
          value: "salesIncRelativeLast30",
          label: "Sale Increase % Last 30min"
        },
        {
          value: "salesIncLastDay",
          label: "Sale Increase # Today"
        },
        {
          value: "salesIncRelativeLastDay",
          label: "Sale Increase % Last Day"
        },
        {
          value: "dsQuantity",
          label: "DS Quantity"
        }
      ]
    },
  ]
];

import React, { Component } from "react";
import moment from "moment-timezone";
import StringFormatter from "../../../utils/StringFormatter";
import NumberFormatter from "../../../utils/NumberFormatter";

class DailyNewFollowersPuck extends Component {
  constructor(props) {
    super(props);
    this.state = { currentFieldIndex: 0 };
  }

  parseData() {
    const data = this.props.data.data;
    const properties = Object.keys(data);

    const fieldValue = properties[this.state.currentFieldIndex];
    if (data[fieldValue].length > 0) {
      const item = data[fieldValue][data[fieldValue].length - 1];
      const amountTotal = NumberFormatter.shorten(item.value);
      const amountDiff = NumberFormatter.shorten(item.daily_diff);
      const lastWeekDiffAverage = this.lastWeekDiffAverage(data[fieldValue]);
      const fieldName = StringFormatter.toPascalCase(fieldValue);
      return { amountDiff, amountTotal, fieldName, lastWeekDiffAverage };
    }
    return null;
  }

  lastWeekDiffAverage(data) {
    const aWeekAgo = moment(
      moment()
        .add(-7, "days")
        .format("YYYY-MM-DD")
    );
    const filtered = data.filter(val =>
      (moment.isMoment(val.timestp)
        ? val.timestp
        : moment(val.timestp)
      ).isAfter(aWeekAgo)
    );
    const total = filtered.reduce((prev, current) => {
      return prev + (current.daily_diff / current.value) * 100;
    }, 0);
    const average = total / 7;
    return average;
  }

  changeField(currentFieldIndex) {
    const data = this.props.data.data;
    const nextIndex = currentFieldIndex + 1;
    const properties = Object.keys(data);
    const hasNext = properties.length > nextIndex;
    const nextData = hasNext && data[properties[nextIndex]];
    const nextHasDiff =
      hasNext &&
      !!nextData &&
      nextData.length > 0 &&
      nextData[nextData.length - 1].daily_diff > 0;
    if (hasNext && !!nextData && !nextHasDiff) {
      return this.changeField(nextIndex);
    }
    if (!hasNext || nextHasDiff) {
      this.setState({ currentFieldIndex: nextHasDiff ? nextIndex : 0 });
    }
  }

  render() {
    const data = this.parseData();
    const info = !!data && this.props.data.info;
    return (
      data && (
        <div
          onClick={this.changeField.bind(this, this.state.currentFieldIndex)}
          style={{
            background: info.backgroundColor,
            color: "white",
            padding: "15px",
            textAlign: "center",
            marginRight: "15px"
          }}
        >
          <div>
            {!!data.amountTotal && <span>{data.amountTotal} Total</span>}&nbsp;
          </div>
          <div>
            <h4>{data.amountDiff} Daily diff</h4>
          </div>
          <div>
            <h4>{data.lastWeekDiffAverage.toFixed(2)}% Avg. L7D</h4>
          </div>
          <div>{data.fieldName}</div>
          <div>
            <i className={`fab fa-${info.icon}`} />
            {` ${info.name} `}
            <i className={`fab fa-${info.icon}`} />
          </div>
        </div>
      )
    );
  }
}

export default DailyNewFollowersPuck;

import React, { Component } from "react";

class CountryList extends Component {
  constructor(props) {
    super(props);
    this.countries = [
      { code: "", name: "World Wide" },
      { code: "AU", name: "Australia" },
      { code: "CA", name: "Canada" },
      { code: "FR", name: "France" },
      { code: "IE", name: "Ireland" },
      { code: "MY", name: "Malaysia" },
      { code: "NZ", name: "New Zealand" },
      { code: "SG", name: "Singapore" },
      { code: "ES", name: "Spain" },
      { code: "GB", name: "United Kingdom" },
      { code: "US", name: "United States" }
    ];
  }

  onChange = event => {
    const country = event.target.value;
    this.props.onChange(country);
  };

  render() {
    const { value } = this.props;
    return (
      <select
        name="countryList"
        value={value || ""}
        onChange={this.onChange.bind(this)}
        className="form-control"
        id="countryList"
      >
        {this.countries.map(country => {
          return (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          );
        })}
      </select>
    );
  }
}

export default CountryList;

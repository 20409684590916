module.exports = {
  toPascalCase(text) {
    if (!text) {
      return text;
    }
    return text.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });
  },

  normalizeArtistName(name) {
    if (!name) {
      return name;
    }
    return name
      .replace(/[’`·՚՛՜՝]/g, "'")
      .replace(/[‐־᠆‑‒–—―﹘﹣－֊]/g, "-")
      .replace(/[àáâãäå]/gi, "a")
      .replace(/æ/gi, "ae")
      .replace(/ç/gi, "c")
      .replace(/[èéêë]/gi, "e")
      .replace(/[ìíîï]/gi, "i")
      .replace(/ñ/gi, "n")
      .replace(/[òóôõö]/gi, "o")
      .replace(/œ/gi, "oe")
      .replace(/[ùúûü]/gi, "u")
      .replace(/[ýÿ]/gi, "y");
  }
};

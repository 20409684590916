import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import ReactTable from "react-table";
import Loading from "../../../components/Loading";

const defaultFilterMethod = (filter, row, field) => {
  return row[field.id].toLowerCase().includes(filter.value.toLowerCase());
};

const displayPercentColumn = props => {
  return (props.value / 100).toLocaleString(navigator.language, {
    style: "percent",
    maximumFractionDigits: 2
  });
};

const filterByNumericEval = field => {
  return (filter, row) => {
    if (row[field] === null || row[field] === undefined) {
      return false;
    }
    try {
      // eslint-disable-next-line
      const evals = eval(`${row[field]}
      ${filter.value}`);
      return evals;
    } catch (error) {
      return true;
    }
  };
};

const sortByNumericEval = (a, b) => {
  if (a === null) {
    return -1;
  }
  if (b === null) {
    return 1;
  }
  return Math.ceil(a - b);
};

class SalesList extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      filtered: [],
      sorted: []
    };
  }

  onFilterChange = filtered => {
    localStorage.setItem(`${this.props.id}-filters`, JSON.stringify(filtered));

    this.setState({filtered});
  };

  onSortChange = sorted => {
    localStorage.setItem(`${this.props.id}-sort`, JSON.stringify(sorted));

    this.setState({sorted});
  };

  componentDidMount() {
    const savedFilters = localStorage.getItem(`${this.props.id}-filters`);
    const savedSort = localStorage.getItem(`${this.props.id}-sort`);

    this.setState({
      filtered: savedFilters ? JSON.parse(savedFilters) : [],
      sorted: savedSort ? JSON.parse(savedSort) : []
    });
  }

  render() {
    let columns = [
      {
        Header: "Event",
        accessor: "event_name",
        filterable: true,
        Cell: props => (
          <>
            {props.original.sg_id && (
              <a
                className={"badge badge-primary"}
                rel="noopener noreferrer"
                target="_blank"
                href={`https://seatgeek.com/e/events/${props.original.sg_id}`}
              >
                SG
              </a>
            )}
            <a
              href={props.original.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.original.event_name}
            </a>
          </>
        )
      },
      {
        Header: "Event Date",
        accessor: "event_date",
        Cell: props => (
          <span>{moment(props.value).format("MMM Do YYYY h:mm A")}</span>
        ),
        sortMethod: (a, b) => {
          return moment(a).isBefore(b) ? -1 : 1;
        }
      },
      {
        Header: "Sale Date",
        accessor: "sale_start_date",
        Cell: props => (
          <span>{moment(props.value).format("MMM Do YYYY h:mm A")}</span>
        ),
        sortMethod: (a, b) => {
          return moment(a).isBefore(b) ? -1 : 1;
        },
        filterable: true,
        Filter: ({filter, onChange}) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{width: "100%"}}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {Array.from({length: 8}, (v, k) => k + 9).map(hour => (
              <option key={hour} value={hour}>
                {hour === 0 ? 12 : hour > 12 ? hour - 12 : hour}:00{" "} {hour < 12 ? "AM" : "PM"}
              </option>
            ))}
          </select>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === "all")
            return true;
          return moment(row.sale_date).hour() === parseInt(filter.value, 10);
        }
      },
      {
        Header: "Venue",
        accessor: "venue_name",
        filterable: true
      },
      {
        Header: "City",
        accessor: "city",
        filterable: true
      },
      {
        Header: "State",
        accessor: "state",
        filterable: true
      },
      {
        Header: "Sale Type",
        accessor: "sale_type",
        filterable: true,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "1") {
            return row.sale_type === 1;
          }
          return row.sale_type === 0;
        },
        Filter: ({filter, onChange}) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{width: "100%"}}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            <option value="0">Presale</option>
            <option value="1">Onsale</option>
          </select>
        ),
        Cell: props => {
          if (props.value === 0) {
            return (
              <span className="custom-badge custom-badge-presale">Presale</span>
            );
          }
          return (
            <span className="custom-badge custom-badge-onsale">OnSale</span>
          );
        }
      },
      {
        id: "latest_count",
        Header: "Latest Count",
        accessor: "latest_count",
        filterable: true,
        filterMethod: filterByNumericEval("latest_count"),
        sortMethod: sortByNumericEval
      },
      {
        id: "sales",
        Header: "Sales",
        accessor: "sales",
        filterable: true,
        filterMethod: filterByNumericEval("sales"),
        sortMethod: sortByNumericEval
      },
      {
        id: "highest_count",
        Header: "Highest Count",
        accessor: "highest_count",
        filterable: true,
        filterMethod: filterByNumericEval("highest_count"),
        sortMethod: sortByNumericEval
      },
      {
        Header: "Percent left",
        id: "percentLeft",
        accessor: v =>
          v.capacity !== 0 ? (v.latest_count / v.capacity) * 100 : 0,
        Cell: displayPercentColumn,
        filterMethod: filterByNumericEval("percentLeft"),
        sortMethod: sortByNumericEval,
        filterable: true
      },
      {
        Header: "Capacity",
        accessor: "capacity",
        filterMethod: filterByNumericEval("capacity"),
        sortMethod: sortByNumericEval,
        filterable: true
      }
    ];

    if (this.props.id === "active") {
      columns = columns.concat([
        {
          Header: "Dec. # Last 5min",
          accessor: "last5_quantity",
          filterMethod: filterByNumericEval("last5_quantity"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          Header: "Dec. # Last 15min",
          accessor: "last15_quantity",
          filterMethod: filterByNumericEval("last15_quantity"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          Header: "Dec. # Last 30min",
          accessor: "last30_quantity",
          filterMethod: filterByNumericEval("last30_quantity"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          Header: "Dec. # Last Day",
          accessor: "lastday_quantity",
          filterMethod: filterByNumericEval("lastday_quantity"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          id: "last5_relative_quantity",
          Header: "Dec. % Last 5min",
          accessor: v => v.last5_relative_quantity * 100,
          Cell: displayPercentColumn,
          filterMethod: filterByNumericEval("last5_relative_quantity"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          id: "last15_relative_quantity",
          Header: "Dec. % Last 15min",
          accessor: v => v.last15_relative_quantity * 100,
          Cell: displayPercentColumn,
          filterMethod: filterByNumericEval("last15_relative_quantity"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          id: "last30_relative_quantity",
          Header: "Dec. % Last 30min",
          accessor: v => v.last30_relative_quantity * 100,
          Cell: displayPercentColumn,
          filterMethod: filterByNumericEval("last30_relative_quantity"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          id: "lastday_relative_quantity",
          Header: "Dec. % Last Day",
          accessor: v => v.lastday_relative_quantity * 100,
          Cell: displayPercentColumn,
          filterMethod: filterByNumericEval("lastday_relative_quantity"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          Header: "Sales # Last 5min",
          accessor: "last5_quantity_sg",
          filterMethod: filterByNumericEval("last5_quantity_sg"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          Header: "Sales # Last 15min",
          accessor: "last15_quantity_sg",
          filterMethod: filterByNumericEval("last15_quantity_sg"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          Header: "Sales # Last 30min",
          accessor: "last30_quantity_sg",
          filterMethod: filterByNumericEval("last30_quantity_sg"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          id: "last5_relative_quantity_sg",
          Header: "Sales % Last 5min",
          accessor: v => v.last5_relative_quantity_sg * 100,
          Cell: displayPercentColumn,
          filterMethod: filterByNumericEval("last5_relative_quantity_sg"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          id: "last15_relative_quantity_sg",
          Header: "Sales % Last 15min",
          accessor: v => v.last15_relative_quantity_sg * 100,
          Cell: displayPercentColumn,
          filterMethod: filterByNumericEval("last15_relative_quantity_sg"),
          sortMethod: sortByNumericEval,
          filterable: true
        },
        {
          id: "last30_relative_quantity_sg",
          Header: "Sales % Last 30min",
          accessor: v => v.last30_relative_quantity_sg * 100,
          Cell: displayPercentColumn,
          filterMethod: filterByNumericEval("last30_relative_quantity_sg"),
          sortMethod: sortByNumericEval,
          filterable: true
        }
      ]);
    }

    const {events, loading} = this.props;

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-filled">
            <div className="panel-heading">
              {this.props.id === "active" ? "Active" : "Archived"} Events
            </div>
            <div className="panel-body" style={{display: "block"}}>
              {!loading && (
                <ReactTable
                  onFilteredChange={this.onFilterChange}
                  onSortedChange={this.onSortChange}
                  filtered={this.state.filtered}
                  defaultSorted={this.state.sorted}
                  defaultSortDesc
                  defaultFilterMethod={defaultFilterMethod}
                  columns={columns}
                  data={events}
                />
              )}
              {loading && <Loading/>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SalesList;

import React, {Component} from "react";
import packageJson from "../../../../package.json";

class Topbar extends Component {
    render() {
        return (
            <nav className="navbar navbar-default navbar-fixed-top">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <div id="mobile-menu">
                            <div className="left-nav-toggle">
                                <button href="#" onClick={this.props.toggleMenu}>
                                    <i className="stroke-hamburgermenu"/>
                                </button>
                            </div>
                        </div>
                        <a className="navbar-brand" href="index.html">
                            SOS
                            <span>v.{packageJson.version}</span>
                        </a>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse">
                        <div className="left-nav-toggle" onClick={this.props.toggleMenu}>
                            <button>
                                <i className="stroke-hamburgermenu"/>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Topbar;

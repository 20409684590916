import React, { Component } from "react";
import Tweet from "./Tweet";
import Loading from "../../../components/Loading";

class Last5Tweets extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchTweets();
  }

  fetchTweets = async () => {
    this.setState({ loading: true });
    const twitterAccount = this.props.twitterAccount;
    const response = await fetch(
      `${
        process.env.REACT_APP_SOME_API_URL
      }/api/twitter/byArtist?a=${twitterAccount}`,
      { method: "GET" }
    );
    let tweets = await response.json();
    tweets = !!tweets && tweets.slice(0, 5);
    this.setState({
      tweets,
      loading: false
    });
  };

  render() {
    const { tweets, loading } = this.state;

    return (
      <div>
        <h4>Tweets : </h4>
        <div id="last5TweetsContainer">
          {!!tweets ? (
            tweets.map(t => <Tweet key={t.id} data={t} />)
          ) : loading && (
            <Loading />
          )}
        </div>
      </div>
    );
  }
}

export default Last5Tweets;

import React, {Component} from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import Loading from "../../../components/Loading";
import moment from "moment-timezone";
import SendNotifications from "./SendNotifications";

// Filter function for numeric columns
const filterByNumericEval = field => {
    return (filter, row) => {
        if (row[field] === null || row[field] === undefined || isNaN(filter.value)) {
            return false;
        }
        try {
            return row[field] >= filter.value;
        } catch (error) {
            return true;
        }
    };
};

// Sort function for numeric columns
const sortByNumericEval = (a, b) => {
    if (a === null) {
        return -1;
    }
    if (b === null) {
        return 1;
    }
    return Math.ceil(a - b);
};

// Component to display the list of events
class EventList extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            events: props.events,
            loading: true,
            modalIsOpen: false,
        };
    }

    // Set loading to false after component mounts
    componentDidMount() {
        this.setState({
            loading: false
        });
    }

    // Update events when new props are received
    componentWillReceiveProps(nextProps) {
        this.setState({
            events: nextProps.events,
        });
    }

    // Render the component
    render() {
        const {loading} = this.props;

        // All the columns to display in the table
        let columns = [
            {
                Header: "Notify in Slack",
                accessor: "notification_sent",
                filterable: false,
                Cell: props => (
                    <SendNotifications event={props.original} graph={false} fetchEvents={this.props.fetchEvents}/>
                ),
                width: 200,
            },
            {
                Header: "",
                accessor: "line",
                filterable: false,
                Cell: props => (
                    <hr style={{
                        borderLeft: '1px solid #eee',
                        height: '100%',
                        margin: '0',
                        marginLeft: '10px'
                    }}/>
                ),
                width: 30,
            },
            {
                Header: "Event",
                accessor: "name",
                filterable: true,
                Cell: props => (
                    <>
                        <a
                            href={`https://www.ticketmaster.com/event/${props.original.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {props.value}
                        </a>
                    </>
                ),
                width: 400,
            },
            {
                Header: "Event Date",
                accessor: "event_date",
                Cell: props => (
                    <span>{moment(props.value).format("MMM Do YYYY h:mm A")}</span>
                ),
                sortMethod: (a, b) => {
                    return moment(a).isBefore(b) ? -1 : 1;
                }
            },
            {
                Header: "Venue",
                accessor: "venue",
                filterable: true
            },
            {
                Header: "Capacity",
                accessor: "capacity",
                filterable: true,
                filterMethod: filterByNumericEval("capacity"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "SG Sold (T)",
                accessor: "tickets_sold_sg",
                filterable: true,
                filterMethod: filterByNumericEval("tickets_sold_sg"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "SG Sold (L)",
                accessor: "listings_sold_sg",
                filterable: true,
                filterMethod: filterByNumericEval("listings_sold_sg"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "TM MIR",
                accessor: "tm_mir",
                filterable: true,
                filterMethod: filterByNumericEval("tm_mir"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "TM MQR",
                accessor: "tm_mqr",
                filterable: true,
                filterMethod: filterByNumericEval("tm_mqr"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "Count (Promo)",
                accessor: "latest_cw_count",
                filterable: true,
                filterMethod: filterByNumericEval("latest_cw_count"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "Count (All)",
                accessor: "latest_count",
                filterable: true,
                filterMethod: filterByNumericEval("latest_count"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "Decrease (15min)",
                accessor: "decrease_15",
                filterable: true,
                filterMethod: filterByNumericEval("decrease_15"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "SG Get In",
                accessor: "sg_getin",
                filterable: true,
                filterMethod: filterByNumericEval("sg_getin"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "SG Listings",
                accessor: "sg_listings",
                filterable: true,
                filterMethod: filterByNumericEval("sg_listings"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "SG Delta",
                accessor: "sg_delta",
                filterable: true,
                filterMethod: filterByNumericEval("sg_delta"),
                sortMethod: sortByNumericEval
            },

        ];

        // Render the table
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="panel panel-filled">
                        <div className="panel-body" style={{display: "block"}}>

                            {!loading && (
                                <ReactTable
                                    onFilteredChange={this.onFilterChange}
                                    onSortedChange={this.onSortChange}
                                    filtered={this.state.filtered}
                                    defaultSorted={this.state.sorted}
                                    defaultSortDesc
                                    columns={columns}
                                    defaultPageSize={this.state.events.length}
                                    showPagination={false}
                                    data={this.state.events}
                                />
                            )}
                            {loading && <Loading/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EventList;

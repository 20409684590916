import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import ReactTable from "react-table";
import Loading from "../../../components/Loading";

const defaultFilterMethod = (filter, row, field) => {
    return row[field.id].toLowerCase().includes(filter.value.toLowerCase());
};

const displayPercentColumn = props => {
    return (props.value / 100).toLocaleString(navigator.language, {
        style: "percent",
        maximumFractionDigits: 2
    });
};

const filterByNumericEval = field => {
    return (filter, row) => {
        if (row[field] === null || row[field] === undefined || isNaN(filter.value)) {
            return false;
        }
        try {
            // eslint-disable-next-line
            //const evals = eval(`${row[field]} ${filter.value}`);
            return row[field] >= filter.value;
        } catch (error) {
            return true;
        }
    };
};

const sortByNumericEval = (a, b) => {
    if (a === null) {
        return -1;
    }
    if (b === null) {
        return 1;
    }
    return Math.ceil(a - b);
};

class EventsList extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            filtered: [],
            sorted: []
        };
    }

    onFilterChange = filtered => {
        localStorage.setItem(`${this.props.id}-filters`, JSON.stringify(filtered));

        this.setState({filtered});
    };

    onSortChange = sorted => {
        localStorage.setItem(`${this.props.id}-sort`, JSON.stringify(sorted));

        this.setState({sorted});
    };

    componentDidMount() {
        const savedFilters = localStorage.getItem(`${this.props.id}-filters`);
        const savedSort = localStorage.getItem(`${this.props.id}-sort`);

        this.setState({
            filtered: savedFilters ? JSON.parse(savedFilters) : [],
            sorted: savedSort ? JSON.parse(savedSort) : []
        });
    }

    render() {
        let columns = [
            {
                Header: "Event",
                accessor: "name",
                filterable: true,
                Cell: props => (
                    <>
                        <a
                            href={`https://www.ticketmaster.com/event/${props.original.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {props.value}
                        </a>
                    </>
                )
            },
            {
                Header: "Event Date",
                accessor: "event_date",
                Cell: props => (
                    <span>{moment.utc(props.value).tz(props.original.timezone).format("MMM Do YYYY h:mm A")}</span>
                ),
                sortMethod: (a, b) => {
                    return moment(a).isBefore(b) ? -1 : 1;
                }
            },
            {
                Header: "Venue",
                accessor: "venue",
                filterable: true
            },
            {
                Header: "Capacity",
                accessor: "capacity",
                filterable: true,
                filterMethod: filterByNumericEval("capacity"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "SG Sold (T)",
                accessor: "tickets_sold_sg",
                filterable: true,
                filterMethod: filterByNumericEval("tickets_sold_sg"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "SG Sold (L)",
                accessor: "listings_sold_sg",
                filterable: true,
                filterMethod: filterByNumericEval("listings_sold_sg"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "TM MIR",
                accessor: "tm_mir",
                filterable: true,
                filterMethod: filterByNumericEval("tm_mir"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "TM MQR",
                accessor: "tm_mqr",
                filterable: true,
                filterMethod: filterByNumericEval("tm_mqr"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "Count (Promo)",
                accessor: "latest_cw_count",
                filterable: true,
                filterMethod: filterByNumericEval("latest_cw_count"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "Count (All)",
                accessor: "latest_count",
                filterable: true,
                filterMethod: filterByNumericEval("latest_count"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "Decrease (15min)",
                accessor: "decrease_15",
                filterable: true,
                filterMethod: filterByNumericEval("decrease_15"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "Decrease (Total)",
                accessor: "decrease",
                filterable: true,
                filterMethod: filterByNumericEval("decrease"),
                sortMethod: sortByNumericEval
            },
            {
                Header: "Qty Purchased",
                accessor: "qty_purchased",
                filterable: true,
                filterMethod: filterByNumericEval("qty_purchased"),
                sortMethod: sortByNumericEval
            },
        ];


        const {events, loading} = this.props;

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="panel panel-filled">
                        <div className="panel-heading">
                            {this.props.id === "active" ? "Active" : "Archived"} Events
                        </div>
                        <div className="panel-body" style={{display: "block"}}>
                            {!loading && (
                                <ReactTable
                                    onFilteredChange={this.onFilterChange}
                                    onSortedChange={this.onSortChange}
                                    filtered={this.state.filtered}
                                    defaultSorted={this.state.sorted}
                                    defaultSortDesc
                                    defaultFilterMethod={defaultFilterMethod}
                                    columns={columns}
                                    data={events}
                                />
                            )}
                            {loading && <Loading/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EventsList;

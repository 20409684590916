import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Layout from "../containers/_layout";
import SalesGraph from "../containers/sales_graph";
import ActiveSalesList from "../containers/sales_list/ActiveList";
import ArchiveSalesList from "../containers/sales_list/ArchiveList";
import PreSaleList from "../containers/sos/PreSaleList";
import OnSaleList from "../containers/sos/OnSaleList";
import ArtistPage from "../containers/social_media/ArtistPage";
import CppTablePage from "../containers/social_media/CppTablePage";
import CppGraphPage from "../containers/social_media/CppGraphPage";
import TweetList from "../containers/social_media/TweetList";
import ActiveCW from "../containers/cw_graph";
import ListCW from "../containers/cw_list/ActiveList";
import NotifyCW from "../containers/cw_notify";
import LogRocket from "logrocket";
import Login from "../components/Login";

const ActiveSalesGraph = () => <SalesGraph id={"active-sale-graph"}/>;
const YesterdaySalesGraph = () => <SalesGraph yesterday={true} id={"yesterday-sale-graph"}/>;
const FavoriteSales = () => <SalesGraph id={"favorite-sale-graph"} onlyFavorite/>;

const Router = props => {
  const PWD = 'tickets09';

  const [isAuthenticated, setIsAuthenticated] = React.useState(null);

  React.useEffect(() => {
    const pwd = localStorage.getItem('pwd');
    const email = localStorage.getItem('email');
    if (pwd === PWD) setIsAuthenticated(true);
    else {
      setIsAuthenticated(false);
      localStorage.removeItem('pwd');
      localStorage.removeItem('email');
    }

    if (email && pwd === PWD) setupLogRocket({id: email, name: email, email});
  }, []);

  const setupLogRocket = (user) => {
    LogRocket.identify(user.id, {
      name: user.name,
      email: user.email,
    });
  };

  return (
    <BrowserRouter>
      {isAuthenticated ? (
        <Switch>
          <Route
            path="/"
            render={() => {
              return wrappedRoutes();
            }}
          />
        </Switch>
      ) : (
        isAuthenticated === false && (
          <Login/>
        )
      )}
    </BrowserRouter>
  );
};

const wrappedRoutes = user => (
  <Layout>
    <section className="content">
      <div className="container-fluid">
        <Route
          exact
          path="/"
          render={() => <Redirect to="/pages/graph/active"/>}
        />
        <Route path="/pages" component={Pages}/>
      </div>
    </section>
  </Layout>
);

const Pages = () => (
  <Switch>
    <Route path="/pages/graph/active" component={ActiveSalesGraph}/>
    <Route path="/pages/graph/yesterday" component={YesterdaySalesGraph}/>
    <Route path="/pages/graph/favorite" component={FavoriteSales}/>
    <Route path="/pages/list/active" component={ActiveSalesList}/>
    <Route path="/pages/list/active" component={ActiveSalesList}/>
    <Route path="/pages/list/past" component={ArchiveSalesList}/>
    <Route path="/pages/some/tweets" component={TweetList}/>
    <Route path="/pages/some/artist" component={ArtistPage}/>
    <Route path="/pages/some/cppTable" component={CppTablePage}/>
    <Route path="/pages/some/cppGraph" component={CppGraphPage}/>
    <Route path="/pages/sos/presale" component={PreSaleList}/>
    <Route path="/pages/sos/onsale" component={OnSaleList}/>
    <Route path="/pages/cw/active" component={ActiveCW}/>
    <Route path="/pages/cw/list" component={ListCW}/>
    {/* <Route path="/pages/cw/notify" component={NotifyCW}/> */}
  </Switch>
);

export default Router;

import React from "react";
import Select from "react-select";
import AwesomeDebounceOptions from "awesome-debounce-promise";
import { NavLink } from "react-router-dom";
import StringFormatter  from "../../../utils/StringFormatter"

const fetchArtists = async inputValue => {
  return new Promise(async (res, rej) => {
    const config = {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      },
      json: true
    };
    if (inputValue.length > 1) {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_SOME_API_URL
          }/api/artists/filter?q=${inputValue}`,
          config
        );
        const result = await response.json();
        res(result);
      } catch (err) {
        console.error(err);
        rej([]);
      }
    } else {
      res([]);
    }
  });
};
const searchArtistsDebounced = AwesomeDebounceOptions(fetchArtists, 200);

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      inputValue: this.props.value
    };
  }

  componentDidMount() {
    if (this.props.value.length > 1) {
      this.handleChange(this.props.value);
    }
  }

  handleChange = data => {
    this.props.onSelectionChange(data.value);
  };

  async handleInputChange(inputValue) {
    this.setState({ inputValue, options: null });
    const values = await searchArtistsDebounced(inputValue);
    const options = values.map(o => {
      console.log('o', o);
      return { value: o.id, label: StringFormatter.normalizeArtistName(o.name) };
    });
    this.setState({ options });
  }

  formatOptionLabel = ({ value, label, ...props }) => {
    return (
      <div style={{ display: "flex" }}>
        <NavLink to={`/pages/some/artist?q=${value}`}>
          <div>{label}</div>
        </NavLink>
      </div>
    );
  };

  render() {
    const { options, inputValue } = this.state;
    return (
      <Select
        autosize={false}
        name="form-field-name"
        onBlurResetsInput={false}
        inputValue={inputValue}
        options={options || []}
        onInputChange={this.handleInputChange.bind(this)}
        onChange={this.handleChange.bind(this)}
        formatOptionLabel={this.formatOptionLabel}
      />
    );
  }
}

export default SearchBar;

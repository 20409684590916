import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment-timezone";
import "chartjs-plugin-annotation";
import NumberFormatter from "../../../utils/NumberFormatter";
import Loading from "../../../components/Loading";

class CppGraph extends Component {
  generateLabels = artists => {
    return Array.apply(null, { length: 3 }).map(daysToAdd =>
      moment().add(-daysToAdd, "days")
    );
  };

  groupArtistData = artists => {
    return artists
      .reduce((total, current, index) => {
        let found = total.find(
          (val, index) => val.chartmetricId === current.chartmetricId
        );
        const data = { x: current.createdAt, y: current.position };
        if (found) {
          found.values.push(data);
        } else {
          total.push({
            chartmetricId: current.chartmetricId,
            artistName: current.artistName,
            values: [data]
          });
        }
        return total;
      }, []);
  };

  getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  generateDataSet = () => {
    const { artists } = this.props;
    const datasets = [];
    const labels = this.generateLabels(artists);
    const grouped = this.groupArtistData(artists);
    console.log("grouped", grouped);
    grouped.forEach(artist => {
      const backgroundColor = this.getRandomColor();
      datasets.push({
        fill: false,
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: backgroundColor,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        label: artist.artistName,
        data: artist.values,
        yAxisID: "y-axis-1"
      });
    });

    return {
      labels,
      datasets
    };
  };

  generateOptions = () => {
    return {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: false
      },
      tooltips: {
        mode: "x",
        intersect: false,
        position: "nearest",
        callbacks: {
          beforeBody: (items, data) => {
            items.forEach(item => {
              item.yLabel = NumberFormatter.numberWithSpaces(item.yLabel);
            });
          }
        }
      },
      scales: {
        xAxes: [
          {
            id: "x-axis-1",
            type: "time",
            distribution: "linear",
            display: true,
            spanGaps: true,
            time: {
              tooltipFormat: "MMM DD",
              minUnit: "day"
            },
            scaleLabel: {
              display: true,
              labelString: "Time"
            }
          }
        ],
        yAxes: [
          {
            id: "y-axis-1",
            type: "linear",
            position: "right",
            display: true,
            ticks: {
              beginAtZero: false,
              callback: (value, index, values) => {
                return value;
              }
            },
            scaleLabel: {
              display: true,
              labelString: "Who",
              fontColor: "#ffffff",
              fontSize: 11
            }
          }
        ]
      }
    };
  };

  render = () => {
    if (!this.props.artists) {
      return <Loading />;
    }
    console.log("Before DataSet Generation");
    const data = this.generateDataSet();
    console.log("After DataSet Generation");
    const options = this.generateOptions();
    console.log("data", data);
    console.log("options", options);
    return (
      <div style={{ position: "relative", height: "15000px" }}>
        <Line data={data} options={options} />
      </div>
    );
  };
}

export default CppGraph;

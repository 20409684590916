import React, { Component } from "react";
import SingleGraph from "./SingleGraph";

class GraphContainer extends Component {
  constructor(props) {
    super(props);

    this.onGraphChange = this.onGraphChange.bind(this);

    this.state = {
      currentGraphIndex: -1,
      previousSales: props.event.previousSales,
    };
  }

  onGraphChange(idx) {
    this.setState({
      currentGraphIndex: idx,
    });
  }

  render() {
    return (
      <div>
        {this.state.currentGraphIndex > -1 ? null : (
          <SingleGraph
            {...this.props}
            previousId={null}
            nextId={this.state.previousSales.length > 0 ? 0 : null}
            onGraphChange={this.onGraphChange}
            onWatchClick={this.props.onWatchClick}
          />
        )}
        {this.state.previousSales.map((previousSale, idx) => {
          return this.state.currentGraphIndex !== idx ? null : (
            <SingleGraph
              {...this.props}
              event={previousSale}
              key={previousSale.id}
              showSaleDate
              onGraphChange={this.onGraphChange}
              onWatchClick={this.props.onWatchClick}
              previousId={idx - 1}
              nextId={
                idx + 1 >= this.state.previousSales.length ? null : idx + 1
              }
            />
          );
        })}
      </div>
    );
  }
}

export default GraphContainer;

import React, { Component } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";

class DailyNewFolowersCSVDownloader extends Component {
  constructor(props) {
    super(props);
    this.state = { csvData: [] };
  }
  async componentDidMount() {
    const csvData = await this.generateCSVData();
    this.setState({ csvData });
  }

  async getGenres() {
    const response = await fetch(
      `${process.env.REACT_APP_SOME_API_URL}/api/genre`
    );
    const allGenres = await response.json();
    const tags = this.props.cmArtist.tags;
    const genres = tags.map(t => {
      const category = allGenres.find(g => {
        return g.genre === t.name;
      });
      return !!category ? category.master_category : -999;
    });
    return [...new Set(genres)];
  }

  async generateCSVData() {
    const data = this.convertDataByDate();
    const genres = await this.getGenres();
    const result = [];
    data.forEach(item => {
      const spotify = this.platformValues(item, "Spotify", "followers");
      const instagram = this.platformValues(item, "Instagram", "followers");
      const twitter = this.platformValues(item, "Twitter", "followers");
      const youtubeSub = this.platformValues(item, "Youtube", "subscribers");
      const youtubeView = this.platformValues(item, "Youtube", "views");
      const row = {
        Artist: item.Artist,
        "Date Observed": item.date,
        "Spotify Total Followers": spotify.total,
        "Spotify Follower Variation": spotify.variation,
        "Spotify Follower Variation %": spotify.perc,
        "Instagram Total Followers": instagram.total,
        "Instagram Follower Variation": instagram.variation,
        "Instagram Follower Variation %": instagram.perc,
        "Twitter Total Followers": twitter.total,
        "Twitter Follower Variation": twitter.variation,
        "Twitter Follower Variation %": twitter.perc,
        "Youtube Total Followers": youtubeSub.total,
        "Youtube Follower Variation": youtubeSub.variation,
        "Youtube Follower Variation %": youtubeSub.perc,
        "Youtube Total Views": youtubeView.total,
        "Youtube Views Variation": youtubeView.variation,
        "Youtube Views Variation %": youtubeView.perc,
        CPP: item.cpp
      };

      genres.forEach((g, index) => {
        row[`style${index + 1}`] = g;
      });

      result.push(row);
    });
    return result;
  }

  calculateAverages(spotify, instagram, twitter, facebook, youtubeSub, field) {
    return (
      (spotify[field] +
        instagram[field] +
        twitter[field] +
        facebook[field] +
        youtubeSub[field]) /
      5
    );
  }

  platformValues(item, platform, field) {
    const total =
      !!item[platform] && !!item[platform][field]
        ? item[platform][field].value
        : "-999";
    const variation =
      !!item[platform] && !!item[platform][field]
        ? item[platform][field].variation
        : "-999";
    const perc =
      !!item[platform] && !!item[platform][field]
        ? (item[platform][field].variation / item[platform][field].value) * 100
        : "-999";
    return { total, variation, perc };
  }

  convertDataByDate() {
    const rawData = this.props.data;
    const artistName = this.props.artist.name;
    const result = [];

    rawData.forEach(platform => {
      Object.keys(platform.data).forEach(field => {
        let values = platform.data[field];
        if (!!values && values.length > 0) {
          values.forEach(value => {
            let time = moment(value.timestp, "ddd MMM DD YYYY");
            if (!time.isValid()) {
              time = moment(value.timestp);
            }
            const row = result.find(r => r.date === time.format("YYYY-MM-DD"));
            if (!row) {
              const item = {
                Artist: artistName,
                date: time.format("YYYY-MM-DD")
              };
              if (!item[platform.info.name]) {
                item[platform.info.name] = {
                  [field]: { value: value.value, variation: value.daily_diff }
                };
              } else {
                item[platform.info.name][field] = {
                  value: value.value,
                  variation: value.daily_diff
                };
              }
              result.push(item);
            } else {
              if (!row[platform.info.name]) {
                row[platform.info.name] = {
                  [field]: { value: value.value, variation: value.daily_diff }
                };
              } else {
                row[platform.info.name][field] = {
                  value: value.value,
                  variation: value.daily_diff
                };
              }
            }
          });
        }
      });
    });
    return result;
  }

  render() {
    const filename = moment().format("YYYY-MM-DD HH.mm.ss");
    const { artist } = this.props;
    return (
      <CSVLink
        data={this.state.csvData}
        filename={`${artist.name} ${filename}.csv`}
      >
        <i className="fas fa-download fa-2x" />
      </CSVLink>
    );
  }
}

export default DailyNewFolowersCSVDownloader;
